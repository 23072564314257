
export const validateISRC = (isrc) => {
    if (isrc.length !== 12) {
        return {
            isValid: true,
            errorMessage: "ISRC must be exactly 12 characters long."
        };
    }
    const isrcPattern = /^[A-Z]{2}[A-Z0-9]{3}\d{2}\d{5}$/;
    if (!isrcPattern.test(isrc)) {
        return {
            isValid: true,
            errorMessage: "ISRC must be in the format (e.g., USRC17607839)."
        };
    }

    return { isValid: false, errorMessage: "" };
}
