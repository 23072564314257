// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../img/background/dashboardImg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardbanner{  
  border-radius: 4px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  }

  @media (min-width:320px)and (max-width: 924px) {
    .leftStatusCard{
      left : 104.5px !important
    }
    }
    
    @media (min-width:925px)and (max-width: 970px) {
      .leftStatusCard{
        left : 116.5px
      }
      }
    
      @media (min-width:971px)and (max-width: 980px) {
        .leftStatusCard{
          left : 118.5px
        }
        }
    
        @media (min-width:981px)and (max-width: 984px) {
          .leftStatusCard{
            left : 119.5px
          }
          }
    
          @media (min-width:985px)and (max-width: 1000px) {
            .leftStatusCard{
              left : 123.5px
            }
            }
            @media (min-width:1001px)and (max-width: 1098px) {
              .leftStatusCard{
                left : 128.5px
              }
              }
    
              @media (min-width:1099px)and (max-width: 1366px) {
                .leftStatusCard{
                  left : 135.5px
                }
                }
    
              @media (min-width:1367px)and (max-width: 1441px) {
                .leftStatusCard{
                  left : 153.5px
                }
                }
    
                @media (min-width: 1920px) {
                  .leftStatusCard{
                    left : 234.5px
                  }
                  }`, "",{"version":3,"sources":["webpack://./src/module/dashboard/customStyle.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;IAChB,yDAA4D;EAC9D;;EAEA;IACE;MACE;IACF;IACA;;IAEA;MACE;QACE;MACF;MACA;;MAEA;QACE;UACE;QACF;QACA;;QAEA;UACE;YACE;UACF;UACA;;UAEA;YACE;cACE;YACF;YACA;YACA;cACE;gBACE;cACF;cACA;;cAEA;gBACE;kBACE;gBACF;gBACA;;cAEF;gBACE;kBACE;gBACF;gBACA;;gBAEA;kBACE;oBACE;kBACF;kBACA","sourcesContent":[".dashboardbanner{  \n  border-radius: 4px;\n    background-image: url(../../img/background/dashboardImg.png);\n  }\n\n  @media (min-width:320px)and (max-width: 924px) {\n    .leftStatusCard{\n      left : 104.5px !important\n    }\n    }\n    \n    @media (min-width:925px)and (max-width: 970px) {\n      .leftStatusCard{\n        left : 116.5px\n      }\n      }\n    \n      @media (min-width:971px)and (max-width: 980px) {\n        .leftStatusCard{\n          left : 118.5px\n        }\n        }\n    \n        @media (min-width:981px)and (max-width: 984px) {\n          .leftStatusCard{\n            left : 119.5px\n          }\n          }\n    \n          @media (min-width:985px)and (max-width: 1000px) {\n            .leftStatusCard{\n              left : 123.5px\n            }\n            }\n            @media (min-width:1001px)and (max-width: 1098px) {\n              .leftStatusCard{\n                left : 128.5px\n              }\n              }\n    \n              @media (min-width:1099px)and (max-width: 1366px) {\n                .leftStatusCard{\n                  left : 135.5px\n                }\n                }\n    \n              @media (min-width:1367px)and (max-width: 1441px) {\n                .leftStatusCard{\n                  left : 153.5px\n                }\n                }\n    \n                @media (min-width: 1920px) {\n                  .leftStatusCard{\n                    left : 234.5px\n                  }\n                  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
