import React, { useEffect, useState } from 'react'
import imgThumbnail from "../../../img/thumbnail.png"
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { ConstructionOutlined } from '@mui/icons-material';
/* import useMediaQuery from '@mui/material/useMediaQuery'; */
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import profileImge from '../../../img/profileImage.png'
import { fetchUserDetail } from '../../../redux';
/* import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube'; */
import InstagramImg from '../../../img/socialHandles/instagram.png'
import YoutubeImg from '../../../img/socialHandles/youtube.png'
import FacebookImg from '../../../img/socialHandles/facebook2.png'
import LinkedinImg from '../../../img/socialHandles/linkedin.png'
import TwitterImg from '../../../img/socialHandles/twitter.png'
import SpotifyImg from '../../../img/socialHandles/spotify.png'
import website from '../../../img/socialHandles/website.png'
import Imbdlogo from '../../../img/socialHandles/imbd-logo.png'
import { verifyServiceHandler } from '../../../_services/profile/verifyArtist/verifyArtist.service.jsx'
import SnackbarContainer from "../../Snackbar"
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../redux';
import { formSnackbar } from '../../../_services/_constants/snackbar.constant'
import useMediaQuery from '@mui/material/useMediaQuery';
import { gnTranslations } from '../../../_services/_constants/translation.constants';
import { updateProfile } from '../../../_services/profile/profileUpdate.service';
import VerifiedIcon from '@mui/icons-material/Verified';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { getSongStatusBgColor } from '../../../_helpers/reusablefunctions/getColor';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ArtistVerification({ name }) {
    const userData = useSelector((state) => state.userData.userData);
    const dispatch = useDispatch()
    const smallViewport = useMediaQuery('(max-width:768px)');
    const [open, setOpen] = useState(false)
    const [remark, setRemark] = useState('')
    const [remarkError, setRemarkError] = useState('')
    const [errorState, setErrorState] = useState(false)
    const [disbleBtn, setDisableBtn] = useState(false)
    const [paraText, setParaText] = useState('')
    const [suggestionText, setSuggestionText] = useState(null)
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const handleClick = () => {
        setOpenSnackBar(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    let textClass;
    useEffect(() => {
        if (userData.id) {
            userData && userData.verification_status ? getParagraphText(userData.verification_status.verificationStatus.toLowerCase()) : getParagraphText()
            //to check the neccessary input are there before applying for verification
            if (userData) {
                if ((userData.avatar != null && userData.avatar !== undefined) && (userData.social && userData.social.length > 0)) {
                    userData && userData.verification_status && userData.verification_status.verificationStatus.toLowerCase() !== 'unverified' && userData.verification_status.verificationStatus.toLowerCase() !== 'rejected' ? setDisableBtn(true) : setDisableBtn(false)
                    setSuggestionText(null)
                }
                else {
                    setDisableBtn(true)
                    setSuggestionText('Please add your notable social links and profile photo before applying for verification. Our team look into each profile very seriously.')
                }
            }
        }
    }, [userData])
    useEffect(() => {
        dispatch(fetchUserDetail()) // to laod the latest values of artist
    }, [])

    const getCurrentTextClassAsStatus = () => {
        if (userData && userData.verification_status && userData.verification_status.verificationStatus) {
            // getSongStatusBgColor()
            // switch(userData.verification_status.verificationStatus.toLowerCase())
            // {
            //     case 'unverified':
            //         {
            //             return 'text-danger'
            //         }
            //     case 'pending':
            //         {
            //             return 'text-warning'
            //         }  
            //     case 'verified':
            //         {
            //             return 'text-success'
            //         }
            //     case 'rejected':
            //         {
            //             return 'text-danger'
            //         }     
            //     default:
            //     return 'text-danger'      

            // }
        }
        else
            return 'text-danger'
        //return userData && userData.isVerified?'text-success':'text-danger'

    }
    //get current text to get display
    const getParagraphText = (vrStatus) => {
        if (userData && userData.verification_status && userData.verification_status.verificationStatus) {
            switch (vrStatus.toLowerCase()) {
                case 'unverified':
                    {
                        setParaText(gnTranslations.verification.verfnStart)
                        break;
                    }
                case 'pending':
                    {
                        setParaText(gnTranslations.verification.verfnPending)
                        break;
                    }
                case 'rejected':
                    {
                        setParaText(gnTranslations.verification.verfnRejected)
                        break;
                    }
                case 'verified':
                    {
                        setParaText(gnTranslations.verification.verfnApproved)
                        break;
                    }
                default: {
                    setParaText(gnTranslations.verification.verfnStart)
                    break;
                }
            }
        }
        else {
            setParaText(gnTranslations.verification.verfnStart)
        }
    }

    const getSocialIcon = (socialTypeCode) => {
        switch (socialTypeCode) {
            case 'facebook_1': {
                return <img style={{ widht: '32px', height: '32px' }} src={FacebookImg} alt="Not found" />
            }
            case 'instagram_2': {
                return <img style={{ widht: '32px', height: '32px' }} src={InstagramImg} alt="Not found" />
            }
            case 'twitter_3': {
                return <img style={{ widht: '32px', height: '32px' }} src={TwitterImg} alt="Not found" />
            }
            case 'linkedin_4': {
                return <img style={{ widht: '32px', height: '32px' }} src={LinkedinImg} alt="Not found" />
            }
            case 'youtube_5': {
                return <img style={{ widht: '32px', height: '32px' }} src={YoutubeImg} alt="Not found" />
            }
            case 'spotify_6': {
                return <img style={{ widht: '32px', height: '32px' }} src={SpotifyImg} alt="Not found" />
            }
            case 'website': {
                return <img style={{ widht: '32px', height: '32px' }} src={website} alt="Not found" />
            }
            case 'imdb': {
                return <img style={{ widht: '32px', height: '32px' }} src={Imbdlogo} alt="Not found" />
            }
            default:
                return
        }
    }

    const validationHandler = (e) => {
        if (!remark) {
            setRemarkError('Please enter your remark!');
            setErrorState(true)
            return true;
        }
        else if (remark.length > 3000) {
            setRemarkError('Max character limit is 3000');
            setErrorState(true)
            return true;
        }
        else {
            setRemarkError('');
            setErrorState(false)
            return false
        }
    }
    const verificationRequestHandler = (e) => {
        e.preventDefault();
        e.stopPropagation()
        let isNotValid = validationHandler();
        if (isNotValid) {
            return;
        }
        else {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarMessage(formSnackbar.verifyArtist.verficationApply))
            dispatch(setSnackBarVariant('info'))

            setRemarkError('');
            setErrorState(false)
            let dataObj = {}
            dataObj.artist_id = userData.id.toString();
            /* code for verification_status [unverified = 1, pending=2, rejected=4, verified=3] */
            dataObj.verification_status = '2'
            dataObj.artist_remark = remark

            verifyServiceHandler(dataObj, 'POST')
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setSnackBarMessage(formSnackbar.verifyArtist.verificationApplied))
                        dispatch(setSnackBarVariant('success'))
                        setTimeout(() => { dispatch(setSnackBar(false)) }, 2000) // to auto hide the message
                        updateUserInfo()//will be removed later
                        setOpen(false)
                        setDisableBtn(true)
                        //dispatch(fetchUserDetail()) /*  update the redux store with the latest state transition */
                    }
                    else {
                        //alert(`The service has been responded with the error code ${response.status}`)
                        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
                        dispatch(setSnackBarVariant('error'))
                        setTimeout(() => { dispatch(setSnackBar(false)) }, 3000)
                    }
                })
        }
    }

    /* Function created  */
    const updateUserInfo = () => {
        let updateObj = {
            verification_status: '2',
            isverified: false
        }
        updateProfile(updateObj, userData.id)
            .then((response) => {
                dispatch(fetchUserDetail())
            })
    }

    const drawerCloseHandler = () => {
        setRemarkError('');
        setErrorState(false)
        setRemark(null)
        setOpen(false)
    }
    return (
        <>
            {suggestionText && <Alert variant="standard" severity="warning" onClose={() => { setSuggestionText(false) }}>{suggestionText}</Alert>}
            <div className="py-4 px-3 mb-5">
                {/*  Heading Row */}
                <div className="row mb-4">
                    <div className={`col-sm-12 col-md-12 col-lg-12 ${smallViewport ? 'px-1' : 'px-4'}`}>
                        <h2> GNTunes Verification</h2>
                        <p className='paragraph'>{paraText}</p>
                    </div>
                </div>
                {/* body Row */}
                <div>
                    <div className={`d-flex flex-wrap justify-content-between ${smallViewport ? '' : 'px-3'}`}>
                        <div className="verifyCol1 p-4 shadow bg-white rounded">
                            <div>
                                {/* header row 1 */}
                                <div className={`d-flex align-items-center mb-4 flex-wrap ${smallViewport ? 'px-0' : 'px-3'}`}>
                                    <div id="artist-avatar">
                                        <div class="item" style={{ width: '100px' }}>
                                            <div>
                                                {userData && userData.isVerified && <span class="notify-badge"><VerifiedIcon sx={{ color: '#e02221', borderRadius: '50%', backgroundColor: '#fff' }} /></span>}
                                                {<img className="rounded-circle" style={{ height: '100px', width: '100px' }} src={userData && userData.avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + userData.avatar : profileImge} alt="Image not found" />}
                                            </div>
                                        </div>
                                    </div>
                                    <div id="artist-status">
                                        <h2>{userData && userData.name && userData.name} {/* userData && userData.isVerified && <span style={{position:'relative',bottom:'1px'}}><VerifiedIcon sx={{color:'#458EFF'}}/></span> */} </h2>
                                        {/* <div className={`${getCurrentTextClassAsStatus()} d-flex align-items-center justify-content-center w-50 p-1`} style={{border:'solid 2px', borderRadius:'20px', fontSize:'1rem'}}><strong>{userData && userData.isVerified?'Verified':'Unverified'}</strong></div> */}
                                        <div className={`${getCurrentTextClassAsStatus()} d-flex align-items-center justify-content-center w-50 p-1`} style={{ border: 'solid 2px', borderRadius: '20px', fontSize: '1rem' }}><strong>{userData.verification_status ? userData.verification_status.verificationStatus : 'Unverified'}</strong></div>

                                    </div>
                                </div>
                                {/* body row 2 */}
                                <div>
                                    {/* Inner row 1 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">About</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2">
                                            <p className='fw-bold'>
                                                {userData && userData.about ? userData.about : 'No Information'}
                                            </p>
                                        </div>
                                    </div>
                                    {/* Inner row 2 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Category</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2 d-flex flex-wrap">
                                            {userData && userData.category && userData.category.length > 0 ? userData.category.map(currentItem => <span className="me-3 fw-bold" key={currentItem.id}>{currentItem.category}</span>) : <strong>No Information</strong>}
                                        </div>
                                    </div>
                                    {/* Inner Row 3 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Genre</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2 d-flex flex-wrap">
                                            {userData && userData.genres && userData.genres.length > 0 ? userData.genres.map(currentItem => <span className="me-3 fw-bold" key={currentItem.id}>{currentItem.genre}</span>) : <strong>No Information</strong>}
                                        </div>
                                    </div>
                                    {/*  Inner row 4 */}

                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Social Platforms</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2 d-flex flex-wrap gap-2">
                                            {userData && userData.social && userData.social.length > 0 ? userData.social.map(currentItem => <a href={currentItem.url} key={currentItem.id}><span className="me-2">{getSocialIcon(currentItem.socialType.socialTypeCode)}</span></a>) : <strong>No Information</strong>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div style={{ width: '20px', height: '20px' }}></div>
                        <div className="verifyCol2 p-4 shadow bg-white rounded">
                            <div>
                                <div>
                                    {/* row 1 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Experience</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2">
                                            {userData && userData.experience && userData.experience.length > 0 ? userData.experience.map(currentItem => <div className="ms-2 fw-bold" key={currentItem.id}>{currentItem.companyName}</div>) : <strong>No Information</strong>}
                                        </div>
                                    </div>
                                    {/*  row 2 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Language</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2">
                                            {userData && userData.languages && userData.languages.length > 0 ? userData.languages.map(currentItem => <span className="ms-2 fw-bold" key={currentItem.id}>{currentItem.language}</span>) : <strong>No Information</strong>}
                                        </div>
                                    </div>
                                    {/* row 3 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Expertise Level</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2">

                                            {userData && userData.expertiseLevel ? <span className="ms-2 fw-bold">{userData.expertiseLevel.expertiseLevel}</span> : <strong>No Information</strong>}
                                        </div>
                                    </div>
                                    {/* row 4 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Contact Information</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2">
                                            {userData && userData.contactNumber ? <span className='fs-6 fw-bold'>{userData && userData.country &&
                                                //    <img src={`https://flagcdn.com/${userData.country.countryCode.toLowerCase()}.svg`}
                                                //     width="30" alt="South Africa" />
                                                ""
                                            }
                                                {/* {`${userData.country.mobileCode} `}  */}
                                                {userData.contactNumber}</span> : <strong>No Information</strong>}

                                        </div>
                                    </div>
                                    {/* row 4.1 */}
                                    <div className="mb-2">
                                        <div className='d-flex align-items-center'>
                                            <span className="fs-6 text-secondary">Website</span>
                                            <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                        </div>
                                        <div className="m-2">

                                            <div className="mb-2">
                                                <div>
                                                    <strong>User Bio: </strong>
                                                    <a className="me-2" title="Your Profile" target="_blank" href={`${process.env.REACT_APP_CREATORURL}/${userData && userData.userHandle}`}>
                                                        {`${process.env.REACT_APP_CREATORURL}/${userData && userData.userHandle}`}
                                                    </a>
                                                </div>
                                                <div>
                                                    <strong>Playlist: </strong>
                                                    <a target="_blank" title="Your Playlist" href={`${process.env.REACT_APP_CREATORURL}/${userData && userData.userHandle}/playlist`}>
                                                        {`${process.env.REACT_APP_CREATORURL}/${userData && userData.userHandle}/playlist`}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* row 5 */}
                                    <div className="mt-4">
                                        <div style={{ marginTop: '50px' }}>
                                            <button disabled={disbleBtn ? 'disabled' : ''} className="gn-actionbtn" onClick={() => setOpen(true)}>APPLY FOR VERIFICATION</button>
                                            {/* <div className='text-danger mt-2'>{suggestionText}</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Drawer for add platofrom */}
            <Drawer
                sx={{
                    width: '400px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: smallViewport ? '300px' : '400px',
                        boxSizing: 'border-box',
                        backgroundColor: '#F2EDED'
                    },
                }}
                /*  variant="persistent" */
                anchor="right"
                open={open}
            >
                <div>
                    <div className="p-4 shadow d-flex justify-content-between align-items-center" style={{ backgroundColor: '#F6F6F6' }}>
                        <div><h5>Add your remark</h5></div>
                        <div style={{ position: 'relative', top: '-5px' }}><button title="Close" className="icononly-btn" onClick={drawerCloseHandler}><CloseIcon /></button></div>
                    </div>
                    <div className="p-4">
                        <p> Please add your remark, why do you need to be verified?</p>
                        <div className='my-4'>
                            <form onSubmit={verificationRequestHandler}>
                                <div className='mb-4'>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Remark"
                                        multiline
                                        rows={4}
                                        required
                                        //defaultValue="Please add your personal remarks to get verified for sure"
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                        sx={{ width: '100%' }}
                                        error={errorState}
                                        helperText={remarkError}
                                        onBlur={validationHandler}
                                    />
                                </div>
                                <button type="submit" className='gn-actionbtn' /* onClick={verificationRequestHandler} */>Submit</button>
                                <button type="button" className='ms-4 gn-actionbtn outlined' onClick={drawerCloseHandler}>Cancel</button>
                                {/* <Button variant="text">Text</Button> */}
                            </form>
                        </div>
                    </div>

                </div>


                {/* {renderPlatForms(id,handleDrawerClose,platformsList)} */}
            </Drawer>
            <SnackbarContainer />
        </>
    )
}

export default ArtistVerification
