import { DragIndicator, MoreVert } from '@mui/icons-material';
import React, { useState } from 'react';
import artist from '../../../../img/Frame 48096170.png';
import { Button, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import spotify from '../../../../img/streaming-platform-images/spotify.svg';
import JioSaavn from '../../../../img/streaming-platform-images/jio-saavn.svg';
import Wynk from '../../../../img/streaming-platform-images/wynk.svg';
import AmzazonMusic from '../../../../img/streaming-platform-images/amazon-music.svg';
import Gaana from '../../../../img/streaming-platform-images/gaana.svg';
import AppleMusic from '../../../../img/streaming-platform-images/apple-music.svg';
import Hungama from '../../../../img/streaming-platform-images/hungama.svg';
import YtMusic from '../../../../img/streaming-platform-images/yt-music.svg';
import Instagrammakereels from '../../../../img/streaming-platform-images/instagram_1.svg';
import YouTube from '../../../../img/streaming-platform-images/youtube.png';



const PlaylistSongItem = ({ songData, removeSongFromPlayList, provided }) => {
  const mdScreen = useMediaQuery('(max-width:768px)');
  const xmScreen = useMediaQuery('(max-width:300px)');
  const smScreen = useMediaQuery('(max-width:600px)');
  const [isDragging, setIsDragging] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveClick = () => {
    removeSongFromPlayList(songData.id);
    setAnchorEl(null); // Close the menu after removing
  };

  const frameWidth = 8; // Set your desired frame width
  const frameHeight = 4; // Set your desired frame height

  const frameStyle = {
    width: xmScreen ? '5rem' : '8rem',
    height: xmScreen ? '3rem' : '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: 'black',
    marginRight: '10px',
  };
  const iconStyle = {
    position: 'absolute', // Position the MoreVert icon absolutely
    top: '5px', // Adjust the top and right values to position it as needed
    right: '2px',
  };
  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };
  const iconButtonStyle = {
    background: 'transparent', // Set a transparent background to remove the gray color

  };


  const getStrPlatformImg = (platform_key) => {
    console.debug("getStrPlatformImg:platform_key", platform_key)
    switch (platform_key) {
      case 'jiosaavn':
        return JioSaavn;
      case 'wynk':
        return Wynk;
      case 'spotify':
        return spotify;
      case 'hungama':
        return Hungama;
      case 'gaana':
        return Gaana;
      case 'amazonmusic':
        return AmzazonMusic;
      case 'applemusic':
        return AppleMusic;
      case 'youtube':
        return YouTube;
      case 'youtubemusic':
        return YtMusic;
      case 'Instagrammakereels':
        return Instagrammakereels;
      default:
        return JioSaavn;
    }
  };
  console.debug("songData:songData", songData)

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      <div
        className={`list-container ${isDragging ? 'dragging' : ''}`}
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      // style={{ paddingLeft: '6px' ,paddingRight:'6px'}}
      >
        <div className="col-md-12 my-3 col-sm-12 col-12 rounded-3 border shadow-sm bg-white">
          <div className="link-box">
            <div className="drag-hanlder" {...provided.dragHandleProps} style={{ minWidth: smScreen ? '24px' : '36px' }}>
              <div className="my-auto text-secondary cursorGrab text-center">
                <DragIndicator />
              </div>
            </div>
            <div className='w-100 p-2'>
              <div className="link-inputs">
                <div style={{ width: '100%' }}>
                  <div className='d-flex'>
                    <div className='rounded-1' style={frameStyle}>
                      <img
                        className='rounded-1'
                        src={songData && songData.songPoster ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + songData.songPoster : artist}
                        alt="img"
                        style={imageStyle}
                      />
                    </div>
                    <div style={{ paddingLeft: mdScreen ? '' : '0.5rem', width: '100%' }}>
                      <div className='d-flex justify-content-between'>
                        <div className='w-100'>
                          <p className='titlea' id='video-title' title={songData.songTitle} style={{ width: smScreen ? '80%' : '90%', fontSize: smScreen ? '0.7rem' : '' }}>
                            {songData.songTitle}
                          </p>
                        </div>
                        <div >
                          {!mdScreen ? (
                            <Button variant="text" color='error' onClick={() => removeSongFromPlayList(songData.id)}>
                              <strong style={{ fontSize: mdScreen ? '10px' : '12px' }}>REMOVE</strong>
                            </Button>
                          ) : (
                            <div style={{ marginLeft: '10px' }}>
                              <IconButton aria-label="more" aria-controls="song-menu" onClick={handleClick} style={{ ...iconStyle, ...iconButtonStyle }}>
                                <MoreVert />
                              </IconButton>
                            </div>
                          )}
                          <Menu
                            id="song-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={isMenuOpen}
                            onClose={handleClose}
                            sx={{ marginLeft: '-20px' }}
                          >
                            <MenuItem onClick={handleRemoveClick}>Remove</MenuItem>
                          </Menu>
                        </div>
                      </div>
                      <div className='d-flex' style={{ flexWrap: 'wrap', marginTop: '4px' }}>
                        {songData && songData.relatedPlatform && songData.relatedPlatform.length > 0 && !mdScreen ? (
                          songData.relatedPlatform.map(platform => (
                            <div key={platform.streamingPlatformKey}>
                              {platform.streamingSonglink ? <a target="_blank" href={platform.streamingSonglink ? platform.streamingSonglink : '#'}>
                                <img src={getStrPlatformImg(platform.streamingPlatformKey)} alt='' style={{ width: '25px', height: '25px', marginRight: '10px' }} />
                              </a>
                                :
                                <img src={getStrPlatformImg(platform.streamingPlatformKey)} alt='' style={{ width: '25px', height: '25px', marginRight: '10px' }} />
                              }
                            </div>
                          ))
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlaylistSongItem;
