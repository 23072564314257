
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { Card, CardContent, Stack, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { handleEnquiry } from '../../../_services/creater/enquiry.service';
import { fetchLoginUser, fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../redux';
import { formSnackbar } from '../../../_services/_constants/snackbar.constant'
import SnackbarContainer from '../../../module/Snackbar';
import EnquiryList from '../../../components/EnquiryList';
import { useHistory } from 'react-router-dom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'first_name',
    label: 'Full Name',
    width: 250,
  },
  // {
  //   id: 'LastName',
  //   label: 'Last Name',
  //   width:250,
  // },
  {
    id: 'email',
    label: 'Email',
    width: 250,
  },
  {
    id: 'mobile_number.',
    label: 'Mobile Number',
    width: 250,
  },
  {
    id: 'message',
    label: 'Message',
    width: 250,
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, handleRequestSort, numSelected, rowCount, onRequestSort, } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        {headCells.map((headCell) =>
        (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            onClick={(event) => handleRequestSort(event, 'first_name')}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes =
{
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default function Enquiries() {
  const [order, setOrder] = useState('asc');
  const userData = useSelector((state) => state.userData.userData);
  const [rows, setRows] = useState([]);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const [orderBy, setOrderBy] = useState();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userEnquire, setUserEnquire] = useState()
  const dispatch = useDispatch();
  let smScreen = useMediaQuery('(max-width:600px)')
  const history = useHistory();


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);

      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /*     useEffect(function () 
      {
        setUserEnquire(userDetail ? userDetail.enquire : "")
      }, [userDetail]); */

  useEffect(function () {
    if (userData.id) {
      dispatch(fetchLoginUser(userData.id));
      getData(userData.id);
      setUserEnquire(userDetail ? userDetail.enquire : "")
    }
  }, [userData.id]);

  function getData(userId) {
    handleEnquiry({ 'userId': userId }, 'GET').then(function (response) {
      dispatch(setLoader(false))
      let arr = response.data.data.reverse();
      setRows(arr)
      dispatch(fetchUserDetail())

    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }

  const removeData = () => {
    if (selected.length !== undefined && selected.length === 1) {
      dispatch(setSnackBarVariant('info'))
      dispatch(setSnackBarMessage(formSnackbar.enquireForm.enquireFormDelete))
      setTimeout(() => dispatch(setSnackBar(false)), 2000)
      dispatch(setSnackBar(true))
      let itemId = selected
      handleEnquiry({ 'itemId': itemId }, 'DELETE').then(function (response) {
        if (response.status === 200 || response.status === 204) {
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.enquireForm.enquireFormDeleteSuccess))
          dispatch(setLoader(false))
          setTimeout(() => dispatch(setSnackBar(false)), 2000)
          if (userData.id) {
            getData(userData.id);
          }
          setSelected([]);

        }
        else {
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
          dispatch(setSnackBarVariant('error'))
        }
      })
    }
    else
      if (selected.length > 1) {
        dispatch(setSnackBarVariant('info'))
        dispatch(setSnackBarMessage(formSnackbar.enquireForm.enquireFormDelete))
        setTimeout(() => dispatch(setSnackBar(false)), 2000)
        dispatch(setSnackBar(true))
        let itemId = selected
        let data = { 'pk_ids': itemId.join() }
        handleEnquiry(data, 'POST').then(function (response) {
          if (response.status === 200 || response.status === 204) {
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.enquireForm.enquireFormDeleteSuccess))
            dispatch(setLoader(false))
            setTimeout(() => dispatch(setSnackBar(false)), 2000)
            if (userData.id) {
              getData(userData.id); //to get then enquiry details on the basis
            }
            setSelected([]);
          }
          else {
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            dispatch(setSnackBarVariant('error'))
          }
        })

      }
  }

  const handleRedirectDetail = (e, data) => {
    history.push('/enquiryDetail', { data });
  }


  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <div className="row m-0">
        <h2 >Enquiries</h2>
        {!smScreen ?
          <div>
            <div>
              <Toolbar
                sx={{
                  pl: { sm: 1 },
                  pr: { xs: 3, sm: 3 },
                  ...(selected.length > 0 && {
                    bgcolor: (theme) =>
                      alpha(theme.palette.primary.main, 0.15),
                  }),
                }}
              >
                {selected.length > 0 ? (
                  <Typography>
                    {selected.length} selected
                  </Typography>
                ) : (
                  <Typography>
                  </Typography>
                )}
                {selected.length > 0 || selected.length > 1 ? (
                  <Tooltip >
                    <IconButton>
                      <DeleteIcon onClick={removeData} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip>
                    <IconButton>
                    </IconButton>
                  </Tooltip>
                )}
              </Toolbar>
            </div>
            <Stack style={{ height: '100%' }}>
              <Card sx={{ minWidth: '100%', height: 'auto', boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.18)', borderRadius: '6px' }}>
                <CardContent sx={{ padding: '0px !important' }}>

                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                                className='cursorPointer'
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    onChange={(event) => handleClick(event, row.id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </TableCell>
                                <TableCell

                                  onClick={(e) => handleRedirectDetail(e, row)}
                                  align="left"
                                >
                                  {row.first_name}{row.last_name}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={(e) => handleRedirectDetail(e, row)}
                                >
                                  {row.email}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={(e) => handleRedirectDetail(e, row)}
                                >
                                  {row.mobile_number}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  title={row.message}
                                  onClick={(e) => handleRedirectDetail(e, row)}
                                >
                                  {row.message.length > 25 ? `${row.message.slice(0, 70)}...` : row.message}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                </CardContent>
              </Card >
            </Stack>
          </div> :
          rows.map((row, index) => (
            <div key={row.id}>
              <EnquiryList cardData={row} moduleName="enquiryList" />
            </div>
          ))}

      </div>
      <SnackbarContainer />
    </div>
  );
}