import React, { useEffect, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, useMediaQuery } from '@mui/material';
import defaultImge from '../../img/userAdmin.png'
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import InstagramImg from '../../img/socialHandles/instagram.png'
import YoutubeImg from '../../img/socialHandles/youtube.png'
import FacebookImg from '../../img/socialHandles/facebook2.png'
import TwitterImg from '../../img/socialHandles/twitter.png'
import SpotifyImg from '../../img/socialHandles/spotify.png'
import website from '../../img/socialHandles/website.png'
import apple from '../../img/socialHandles/apple.png'
import soundcloud from '../../img/socialHandles/soundcloud.png'
import tiktok from '../../img/socialHandles/tiktok.png'
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import { useRef } from 'react';
import ArtistReleaseList from '../../components/ArtistReleaseList';
import { default_limit, record_limits } from '../../_services/_constants/recordLimitset.constant';

// Define the menu item icon style
const menuItemIconStyle = {
  marginRight: '8px',
  fontSize: '1.5rem'
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'song_name',
    label: 'Release Detail',
    width: '200',
  },
  {
    id: 'role',
    label: 'Artist Role',
    width: '400',
  },
  {
    id: 'song_status',
    label: 'Status',
    width: '250',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes =
{
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function AssociateArtistDetails() {
  let smScreen = useMediaQuery('(max-width:600px)')
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([])
  let history = useHistory();
  const ref = useRef();
  let params = useParams();
  const [associateDetail, setAssociateDetail] = useState([])
  const userData = useSelector(state => state.userData.userData)
  const dispatch = useDispatch()
  const { assoId } = params
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  const [totalCount, setTotalCount] = useState()
  const isNextButtonDisabled = associateDetail && associateDetail.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const isPreviousButtonDisabled = page === 0;

  useEffect(() => {
    if (userData.id) {
      getArtistDetail()
    }
  }, [userData.id])

  const previousPage = () => {
    history.push("/associatedartist");
  }
  function editForm() {
    history.push(`/associatedartist/${assoId}`);
  }

  const getArtistDetail = () => {
    associatedArtistsRequestHandler({ artist_id: assoId }, 'GETARTIST')
      .then(response => {
        setAssociateDetail(response.data)
        response && response.data.hasOwnProperty('artist_code') && getAssociatedArtistReleaseInfo(response.data.artist_code, 0, rowsPerPage)
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const getAssociatedArtistReleaseInfo = (artistCode, newPage, newRowsPerPage) => {
    const newOffset = newPage; // Calculate the new offset

    dispatch(setLoader(true));
    submitSong({ artistCode: artistCode }, 'GETASSOCIATEDARTISTINFO', newRowsPerPage, newOffset)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(setLoader(false));
          setRows(response.data.results);
          setTotalCount(response.data.total_count);
          setPage(newPage); // Update the current page
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      });
  };



  const removeData = (data) => {
    if (data !== undefined) {

      setTimeout(() => dispatch(setSnackBar(false)), 2000)
      dispatch(setSnackBar(true))
      let itemId = data;
      associatedArtistsRequestHandler({ 'itemId': itemId }, 'DELETE').then(function (response) {
        if (response.status === 200 || response.status === 204) {
          dispatch(setLoader(false))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.associatedArtist.artistDeletedSuccess))
          setTimeout(() => dispatch(setSnackBar(false)), 2000)

        }
        else {
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
          dispatch(setSnackBarVariant('error'))
        }
      })
    }
  }
  const handleDeleteItem = (id) => {
    setSelected(id);
    removeData(id)
    previousPage()

  };

  //Table grid starts
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.min(Math.floor((page * rowsPerPage) / newRowsPerPage), Math.ceil(1000 / newRowsPerPage) - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    getAssociatedArtistReleaseInfo(assoId, newPage, newRowsPerPage);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const newOffset = newPage;
    getAssociatedArtistReleaseInfo(assoId, newPage, rowsPerPage, newOffset);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  return (
    <div className='p-4 mb-4'>
      <div className=''>
        <button className="icononly-btn" onClick={previousPage}><ArrowBackIcon /> Back</button>
      </div>
      <Box className="pt-3">
        <div className="card account-bg  ">
          <div className="card-body rounded" style={{ backgroundColor: '#fff' }}>
            <div className='d-flex justify-content-between'>
              <div>
                <h2>
                  {associateDetail.artist_name && associateDetail.artist_name + '`s'} Profile
                </h2>
              </div>
              <div className='d-flex'>
                <div><IconButton onClick={editForm}> <EditIcon /></IconButton></div>
                <div>{rows.length === 0 && <IconButton className='text-secondary' onClick={() => ref.current.handleOpen()}><DeleteIcon /></IconButton>} </div>
                <DeleteModal cardId={associateDetail && associateDetail.artist_code} ref={ref} deleteHandler={handleDeleteItem} />
              </div>
            </div>
            <div className={!smScreen ? 'd-flex' : ''}>
              <div>
                <div className="avatar-preview">
                  <div id="imagePreview" style={{ width: !smScreen ? '200px' : '100px' }}>
                    <img src={associateDetail.artist_avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + associateDetail.artist_avatar : defaultImge} alt='' className="h-100 rounded-circle w-100" />

                  </div>
                </div>
              </div>
              <div className={smScreen ? 'py-4' : 'p-4'}>
                <h6>{associateDetail.artist_name} </h6>
                <h6>GNID:{associateDetail.creator_gnid}</h6>
                <h6>Artist Bio:</h6>
                <div style={{ whiteSpace: 'pre-line', maxWidth: '600px' }}>
                  <strong style={{ color: '#222222b5' }}>{associateDetail.artist_bio}</strong>
                </div>
                <div className={`d-flex text-secondary mt-2 gap-1 ${!smScreen ? '' : 'flex-wrap'}`}>
                  {associateDetail.instagram && (<div><a className='text-secondary' href={`https://www.instagram.com/${associateDetail.instagram}`} target="_blank" title={associateDetail.instagram}>
                    <img style={{ widht: '32px', height: '32px' }} src={InstagramImg} alt="Not found" />
                  </a></div>)}
                  {associateDetail.facebook && (<div><a className='text-secondary' href={associateDetail.facebook} target="_blank" title={associateDetail.facebook}>
                    <img style={{ widht: '32px', height: '32px' }} src={FacebookImg} alt="Not found" />
                  </a></div>)}
                  {associateDetail.youtube && (<div><a className='text-secondary' href={associateDetail.youtube} target="_blank" title={associateDetail.youtube}>
                    <img style={{ widht: '32px', height: '32px' }} src={YoutubeImg} alt="Not found" />
                  </a></div>)}
                  {associateDetail.twitter && (<div><a className='text-secondary' href={`https://twitter.com/${associateDetail.twitter}`} target="_blank" title={associateDetail.twitter}>
                    <img style={{ widht: '32px', height: '32px' }} src={TwitterImg} alt="Not found" />
                  </a></div>)}
                  {associateDetail.website && (<div><a className='text-secondary' href={associateDetail.website} target="_blank" title={associateDetail.website}>

                    <img style={{ widht: '32px', height: '32px' }} src={website} alt="Not found" />
                  </a></div>)}
                  {associateDetail.soundcloud && (<div><a className='text-secondary' href={associateDetail.soundcloud} target="_blank" title={associateDetail.soundcloud}>
                    <img style={{ widht: '32px', height: '32px' }} src={soundcloud} alt="Not found" />
                  </a></div>)}
                  {associateDetail.spotify && (<div><a className='text-secondary' href={`https://open.spotify.com/artist/${associateDetail.spotify}`} target="_blank" title={associateDetail.spotify}>
                    <img style={{ widht: '32px', height: '32px' }} src={SpotifyImg} alt="Not found" />
                  </a></div>)}
                  {associateDetail.tiktok && (<div><a className='text-secondary' href={`https://www.tiktok.com/@${associateDetail.tiktok}`} target="_blank" title={associateDetail.tiktok}>

                    <img style={{ widht: '32px', height: '32px' }} src={tiktok} alt="Not found" />
                  </a></div>)}
                  {associateDetail.apple_music_id && (<div><a className='text-secondary' href={`https://music.apple.com/in/artist/${associateDetail.apple_music_id}`} target="_blank" title={associateDetail.apple_music_id}>

                    <img style={{ widht: '32px', height: '32px' }} src={apple} alt="Not found" />
                  </a></div>)}
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center my-2'>
              <strong className="fs-6" style={{ color: '#1976D2' }}>Releases</strong>
              <hr className='ms-2' style={{ flexGrow: '1', border: '1px  rgba(0, 0, 0, 0.12)' }} />
            </div>
            {!smScreen ?
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {rows.map((row, index) => {
                          const isItemSelected = isSelected(row.name);

                          return (
                            <TableRow
                              hover
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.name}
                              selected={isItemSelected}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell
                                align="left"
                              >
                                {row.song_name}
                              </TableCell>
                              <TableCell align="left">{row.role}</TableCell>
                              <TableCell align="left"><div className='status text-light' style={{ background: getSongStatusBgColor(row.song_status), minWidth: '80px' }}>{row.song_status}</div></TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={record_limits}
                    colSpan={3}
                    sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
                    className='cardPagination'
                    component='div'
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    nextIconButtonProps={{ disabled: isNextButtonDisabled }}
                  />
                </Paper>
              </Box> :
              <div>
                {rows.map((row, index) => (
                  <div key={row.id}>
                    <ArtistReleaseList cardData={row} moduleName="artistReleaseList" />
                  </div>
                ))}
                <div
                  className="d-flex justify-content-between mb-5"
                  style={{ width: '100%', marginTop: '20px' }}
                >
                  <div style={{ width: '50%' }}>
                    <button
                      onClick={() => handleChangePage(null, page - 1)}
                      disabled={isPreviousButtonDisabled}
                      className={`pagination-button ${isPreviousButtonDisabled ? 'disabled' : 'enabled'}`}
                    >
                      Previous
                    </button>
                  </div>
                  <div style={{ width: '20px' }}></div> {/* This creates a gap */}
                  <div style={{ width: '50%' }}>
                    <button
                      onClick={() => handleChangePage(null, page + 1)}
                      disabled={isNextButtonDisabled}
                      className={`pagination-button ${isNextButtonDisabled ? 'disabled' : 'enabled'}`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
      </Box>
    </div>
  )
}

export default AssociateArtistDetails