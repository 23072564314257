import React, { useState, useEffect, useCallback, useRef } from 'react'
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import AddPlatforms from './AddStreamingPlatforms';
import imgThumbnail from "../../../../img/thumbnail.png"
import { handleSongList } from '../../../../_services/creater/creatorSongList.service'
import { useDispatch, useSelector } from 'react-redux';
import { handleTagList } from "../../../../_services/tags/tagList.service"
import { getStreamingPlatforms } from '../../../../_services/creater/streamingPlatform.service'
/* mui imports */
import Drawer from '@mui/material/Drawer';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { handleRelatedPlatform } from '../../../../_services/creater/relatedPlatfom.service'
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../../redux';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant'
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteModal from '../../../../components/reusableComponents/DeleteModal';
import AddIcon from '@mui/icons-material/Add';
import CreatePlaylistModal from '../playlist/CreatePlaylistModal'
import { ListItemIcon, Menu, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import platform from '../../../../img/Platform.svg'
import playlist from '../../../../img/Playlist.svg'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { updatePlateformSequence } from '../../../../_services/platform/platformSequence';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '4px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export const StreamingPlatformContext = React.createContext();

const AddSong = ({ deleteSongHandler, index, id, data, songTitleProps, provided, disableHandler, renderSongList, songsListData }) => {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData.userData);
  const smallViewport = useMediaQuery('(max-width:768px)');
  const [songTitle, setSongTitle] = useState(data != undefined && data.songTitle != null ? data.songTitle : undefined)
  const [songSlug, setSongSlug] = useState(data != undefined && data.slug != null ? data.slug : undefined)
  const [visiblityStatus, setVisibilityStatus] = useState(data != undefined && data != null ? data.visible ? songTitle ? true : false : false : false)
  const [songPoster, setSongPoster] = useState(setTimeout(() => { setSongPoster(data != undefined && data.songPoster != null ? data.songPoster : null) }, 0))
  //const [songPoster, setSongPoster] = useState()
  const [artist, setArtistList] = useState(data != undefined && data.artists != null && data.artists != "" ? data.artists.split(',') : []);
  const [songTagsList, setSongTagsList] = useState()
  const [selectedTags, setSelectedTags] = useState(data && data.tags != undefined && data.tags.length > 0 ? data.tags.split(',') : [])
  const [relatedPlatform, setRelatedPlatform] = useState([])
  const [disableAddNewPlatform, setDisableAddNewPlatform] = useState(false)
  const [loaderClass, setLoaderClass] = useState('') /* state for image upload loader */
  const [allPlatfromList, setAllPlatformList] = useState()
  const [allPlatfromListCopy, setAllPlatformListCopy] = useState()//to handle the add and remove of the platforms
  /*for input validations */
  const [errorSongTitle, setErrorSongTitle] = useState();
  const [disableViewSatus, setDisableViewStatus] = useState(data != undefined && data.id != undefined ? songTitle ? false : true : true);
  const [errorTags, setErrorTags] = useState();
  const [tagHelperText, setTagHelperText] = useState(null)
  const bodyRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openPlaylistModal, setOpenPlaylistModal] = useState(false);
  const [openPlaylist, setOpenPlaylist] = useState(false);

  // Function to open the "Create Playlist" modal
  const openCreatePlaylistModal = () => {
    setOpenPlaylistModal(true);
  };

  // Function to close the "Create Playlist" modal
  const closeCreatePlaylistModal = () => {
    setOpenPlaylistModal(false);
  };
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenPlaylist = () => {
    setOpenPlaylist(!openPlaylist);
  };
  // const handleClose = () => {
  //   setOpenModal(false);
  // };
  /* For image resize */
  const [posterInfo, setposterInfo] = useState({
    file: [],
    filepreview: null,
  });
  const [invalidImage, setinvalidImage] = useState(null);
  let reader = new FileReader();
  /*end */

  /* for delete confirmation box:start */
  const ref = useRef();
  /*end */


  const handleAllPlatformList = (data, action) => {
    let localDataBKP = [...allPlatfromList]
    if (action === 'remove') {
      setAllPlatformList(localDataBKP.filter(entry1 => !data.some(entry2 => entry1?.platformId === entry2.streamingPlatform))) //only add unselected streaming platoform types
    }
    else if (action === 'add') {
      let platform = allPlatfromListCopy.find(item => item?.platformId === data.streamingPlatform)
      setAllPlatformList([...allPlatfromList, platform])
    }
    else if (action === 'update') {
      //first remove the current selected from the array
      localDataBKP = localDataBKP.filter(item => item?.platformId !== data?.latestValue?.platformId)
      //now add the item which was updated
      let platform = allPlatfromListCopy.find(item => item?.platformId === data.previousValue?.platformId)
      localDataBKP = [...localDataBKP, platform]
      setAllPlatformList(localDataBKP)
    }

  }

  /* Add Platoform:Drawer */
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  }; /* Add Platoform:Drawer:End*/

  /*end */

  const songTitleValidationHandler = (string) => {
    let expression = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/
    let regex = new RegExp(expression);
    if (string !== '' && string !== undefined && string !== null) {
      if (string.match(regex)) {
        return false
      } else {
        setVisibilityStatus(true)
        setErrorSongTitle(null)
        setDisableViewStatus(false)
        return true
      }
    }
    else {
      setVisibilityStatus(false)
      setErrorSongTitle('Please enter a valid song title')
      setDisableViewStatus(true)
      return false
    }
  }
  const songSlugChangeHandler = ({ name, value, previousValue }) => {
    setSongSlug(value)
    userData.id && id == undefined ? createSong({ songSlug: value }) : updateSong({ songSlug: value })
  }
  const songTitleChangeHandler = ({ name, value, previousValue }) => {
    setSongTitle(value)
    let valid = songTitleValidationHandler(value);
    if (valid) {
      setErrorSongTitle(null)
      userData.id && id == undefined ? createSong({ songTitle: value }) : updateSong({ songTitle: value })
    }
    else {
      if (value === '') {
        setErrorSongTitle(null)
        userData.id && id == undefined ? createSong({ songTitle: value }) : updateSong({ songTitle: value })
      }

      else
        setErrorSongTitle('Please enter a valid song title')
    }

  }

  useEffect(() => {
    /* get call for tags list:start */
    let dataObj = {}
    handleTagList(dataObj, 'GET')
      .then((response) => {
        response.status === 200 ? setSongTagsList(response.data.data) : setSongTagsList([])
      })
      .catch((error) => {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
      })
    setSongPoster(data != undefined && data.songPoster != null ? data.songPoster : null) // to add the selected poster: added in useeffect were getting get error for image see the code line no 38
    getRelatedPlatforms();
    getPlatformList();
  }, [])

  const tagsValidationHandler = (tags) => {
    let tagName = tags[tags.length - 1]
    let expression = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/
    let regex = new RegExp(expression);
    if (tagName && tags.length > 0) {
      if (tagName.match(regex)) {
        return false
      } else {
        return true
      }
    }
    else
      return true
  }
  const songTagsChangeHandler = (event, value) => {
    let latestTagList = value
    let isValid = tagsValidationHandler(value)
    if (isValid) {
      setErrorTags(false)
      setTagHelperText(null)
      let newTag = null;
      let availableTag;
      //let unmatchedItem = songTagsList.find((item)=>item.tagName != "" && item.tagName.toLowerCase() != value[value.length-1] )
      let matchedTags = value.length > 0 ? songTagsList.find(item => item.tagName == value[value.length - 1]) : undefined
      matchedTags != undefined ? (availableTag = matchedTags) : (newTag = value[value.length - 1])
      //newTag!=null && createTags({tagName:newTag}) //code to create a new tag from UI
      setSelectedTags(latestTagList)
      userData.id && id == undefined ? createSong({ tags: latestTagList, newTag: newTag }, 'UPDATE_TAGS') : updateSong({ tags: latestTagList, newTag: newTag }, 'UPDATE_TAGS')

    }
    else {
      setErrorTags(true)
      setTagHelperText('Please enter a valid tag name.')
    }
  }


  function songThumbnailChangeHandler(event) {
    const imageFile = event.target.files[0];
    const imageFilname = event.target.files[0].name;
    if (!imageFile) {
      setinvalidImage('Please select an image.');
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
      setinvalidImage('Please select a valid image (JPG, JPEG, PNG).');
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        // Check image dimensions
        if (img.width < 300 || img.height < 300) {
          setinvalidImage('Please select a 300x300 image.');
          return false;
        }
        // Crop image to 100x100 pixels
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 600;
        canvas.height = 600;
        ctx.drawImage(img, 0, 0, 600, 600);

        // Convert cropped image to Blob
        canvas.toBlob((blob) => {
          const file = new File([blob], imageFilname, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });

          setposterInfo({
            ...posterInfo,
            file: file,
            filepreview: URL.createObjectURL(file),
          });

          let image = file; // to upload and save the image
          image ? setLoaderClass('uploadInProgress') : setLoaderClass('');
          userData.id && id == undefined ? createSong({ songPosterThumbnail: image }) : updateSong({ songPosterThumbnail: image });
        }, 'image/jpeg', 1);

        setinvalidImage(null);
      };

      img.onerror = () => {
        setinvalidImage('Please select a valid image.');
        return false;
      };

      img.src = e.target.result;
    };

    reader.readAsDataURL(imageFile);
  }

  const combineChangeHanddler = (event) => {
    event.target.checked ? setVisibilityStatus(true) : setVisibilityStatus(false)
    userData.id && id == undefined ? createSong({ visible: event.target.checked }) : updateSong({ visible: event.target.checked })
  }
  /* Create song function: start */
  const createSong = (postObj, type) => {
    dispatch(setSnackBar(true))
    dispatch(setSnackBarMessage(formSnackbar.songPlaylist.songCreate))
    dispatch(setSnackBarVariant('info'))

    const formData = new FormData();
    //Prepare the object
    postObj.songPosterThumbnail && formData.append('songPoster', postObj.songPosterThumbnail);
    postObj.songTitle && formData.append('songTitle', postObj.songTitle);
    postObj.songSlug && formData.append('slug', postObj.songSlug);
    postObj.visible != undefined && formData.append('visible', postObj.visible);
    if (songsListData.length < 1) {
      formData.append('song_order', '0');
    }
    else {
      /* formData.append('song_order', songsListData.length-1); */
      formData.append('song_order', index);
      let songD = songsListData.map((item, index) => { return { 'songId': item.id ? item.id : 'null', 'index': index } })
      formData.append('songsList', JSON.stringify(songD))
    }

    if (type === 'UPDATE_ARITSTS')
      formData.append('artists', postObj.artists.join(','));
    if (type === 'UPDATE_TAGS') {
      formData.append('tags', postObj.tags);
      //formData.append('newTag', postObj.newTag);
    }
    else {
      //formData.append('tags', selectedTags.length>0?selectedTags.join(','):'song'); //this will be removed currently handled for the service 
    }
    formData.append('userId', userData.id)
    handleSongList(formData, 'POST')
      .then(function (response) {
        if (response.status === 200) {

          dispatch(setSnackBarMessage(formSnackbar.songPlaylist.songCreateSuccess))
          dispatch(setSnackBarVariant('success'))
          setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
          disableHandler()
          renderSongList()
          bodyRef.current.focus();//to remove the focus from image input
        }
        else {
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
          dispatch(setSnackBarVariant('error'))
          setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        dispatch(setSnackBarVariant('error'))
        setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
      })
  }
  /* Create song function: end */
  /*Update song:start*/
  const updateSong = (postObj, type) => {
    //Prepare the object
    dispatch(setSnackBar(true))
    dispatch(setSnackBarMessage(postObj?.songSlug ? formSnackbar.songPlaylist.songSlugUpdate : formSnackbar.songPlaylist.songLinkUpdate))
    dispatch(setSnackBarVariant('info'))
    const formData = new FormData();
    postObj.songPosterThumbnail && formData.append('songPoster', postObj.songPosterThumbnail);
    postObj.songTitle && formData.append('songTitle', postObj.songTitle);
    postObj.songSlug && formData.append('slug', postObj.songSlug);
    postObj.visible != undefined && formData.append('visible', postObj.visible);
    if (type === 'UPDATE_ARITSTS')
      formData.append('artists', postObj.artists.join(','));
    if (type === 'UPDATE_TAGS') {
      formData.append('tags', postObj.tags);
      //formData.append('newTag', postObj.newTag);
    }
    else {
      // formData.append('tags', selectedTags.length>0?selectedTags.join(','):'song'); //this will be removed currently handled for the service 
    }

    formData.append('songId', id)
    formData.append('userId', userData.id)
    handleSongList(formData, 'UPDATE')
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setSnackBarMessage(formSnackbar.songPlaylist.songLinkUpdateSuccess))
          dispatch(setSnackBarMessage(postObj?.songSlug ? formSnackbar.songPlaylist.songSlugUpdateSuccess : formSnackbar.songPlaylist.songLinkUpdateSuccess))
          dispatch(setSnackBarVariant('success'))
          setTimeout(() => { dispatch(setSnackBar(false)) }, 3000)
          renderSongList()
          disableHandler()
          setLoaderClass('')
          bodyRef.current.focus();//to remove the focus from image input
        }
        else {
          if (response.data?.slug.length) {
            dispatch(setSnackBarMessage(response.data?.slug[0]))
          } else {
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
          }
          dispatch(setSnackBarVariant('error'))
          setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        dispatch(setSnackBarVariant('error'))
        setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
      })
  }/*Update song:end*/

  const getPlatformList = () => {
    getStreamingPlatforms()
      .then((response) => {
        if (response.status == 200) {
          setAllPlatformList(response.data.data)
          setAllPlatformListCopy(response.data.data)
        }
        else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
      })
  }

  const reRenderPlatformHandler = () => {
    getRelatedPlatforms();
  }
  const handleAddNewPlatform = () => {
    setRelatedPlatform([{}, ...relatedPlatform])
    setDisableAddNewPlatform(true)
  }

  const deletePlatformHandler = (e, songid, relatedPlatformLinkId, index, relatedPlatformData) => {
    //e.stopPropagation() /* To stop drawer closing on click */
    let copyOfArray = [...relatedPlatform]
    if (copyOfArray.length > 0) {
      setRelatedPlatform(copyOfArray.filter((item, index) => item.id !== relatedPlatformLinkId))
      setDisableAddNewPlatform(false);
      if (relatedPlatformLinkId != undefined) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarMessage(formSnackbar.relatedPlatform.relatedPlatformDelete))
        dispatch(setSnackBarVariant('info'))
        if (JSON.stringify(copyOfArray[0]) === '{}') {
          setDisableAddNewPlatform(true)
        }
        let dataObj = {}
        dataObj.userId = userData.id;
        dataObj.relatedPlatformLinkId = relatedPlatformLinkId;
        dataObj.songId = songid;
        handleRelatedPlatform(dataObj, 'DELETE')
          .then(function (response) {
            if (response.status = 200 || response.status === 204) {
              setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
              dispatch(setSnackBarMessage(formSnackbar.relatedPlatform.relatedPlatformDeleteSuccess))
              dispatch(setSnackBarVariant('success'))
              handleAllPlatformList(relatedPlatformData, 'add')

            }
            else {
              dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
              dispatch(setSnackBarVariant('error'))
              setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
            }
          })
          .catch((error) => {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
          })
      }
    }
  }

  const getRelatedPlatforms = () => {
    let dataObj = {}
    dataObj.songId = id;
    dataObj.userId = userData.id;
    handleRelatedPlatform(dataObj, 'GETBYSONG')
      .then(function (response) {
        if (response.status == 200) {
          // response.data.data.length > 0 && setRelatedPlatform(response.data.data)
          let arrayData = response.data.data;
          const data = arrayData.sort(function (a, b) {
            return parseInt(a.platform_order) - parseInt(b.platform_order);
          });
          setRelatedPlatform(data);
        }
        else {
          dispatch(setSnackBar(true))
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
          dispatch(setSnackBarVariant('error'))
          setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
      })
  }

  const addNewDisabledHandler = () => {
    setDisableAddNewPlatform(false)
  }
  // ----------------Sequence change ----------------
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(relatedPlatform);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setRelatedPlatform(items);
    const data = items.map(song => song.id)
    let dataObj = {
      platform_ids: data.join(',')
    }
    updatePlateformSequence(userData.id, dataObj)
      .then((response) => {
        if (response.status === 200) {
          renderSongList();
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => {
            dispatch(setSnackBar(false));
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => {
          dispatch(setSnackBar(false));
        }, 2000);
      });
  }


  return (
    <>
      <div ref={id !== undefined ? provided.innerRef : null} {...(id !== undefined ? provided.draggableProps : null)} {...(id !== undefined ? provided.dragHandleProps : null)} className="col-md-12 my-3 col-sm-12 col-12 rounded-3 border shadow bg-white" id={`song_${id}`}>
        <div>
          {/* Link desinging start */}
          <div className="link-box">
            <div className="drag-hanlder">
              <div className="my-auto text-secondary cursorGrab text-center">
                <DragIndicatorIcon />
              </div>
            </div>
            <div className="link-details">
              {/*  <form> */}
              <div className="link-inputs"> {/*  link-input start */}
                <div className="profile-wrapper flex-wrap">
                  <div className="profile-pic-wrapper">
                    <div className={`pic-holder ${loaderClass}`}>
                      <img id="pf-img" src={songPoster != null ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + songPoster : imgThumbnail} className="pic rounded-circle  imageOpacity d-block" onError={(e) => { e.target.src = imgThumbnail }} alt="img" /* style={{width:'71px',height:'71px'}} */ />
                      <input className="uploadProfileInput position-absolute" type="file" name="profile_pic" id={`songPoster_${id}`} accept="image/*" style={{ opacity: '0' }} onChange={songThumbnailChangeHandler} />
                      <label htmlFor={`songPoster_${id}`} className="upload-file-block">
                        <div className="text-center" title="Please upload an image with the resolution of max 250*250px">
                          <div className="mb-2">
                            <i className="fa fa-camera fa-2x"></i>
                          </div>
                          <div className="text-uppercase">
                            Update <br />
                          </div>
                        </div>
                      </label>
                      {/* code to display loader */}
                      {loaderClass != '' && <div className="upload-loader"><div className="spinner-border text-light" role="status"><span className="sr-only"></span></div></div>}
                    </div>
                  </div>
                  {invalidImage && <div className={`custom-tooltip`}>
                    <span className="tooltip-text">{invalidImage}</span>
                    <span className="top"></span>
                  </div>}
                </div>
                <div className="link-wrapper">
                  {/* Hide and show link */}
                  <div className="link-input-btns">
                    <div className="form-check form-switch d-flex align-items-center mediaMusic">
                      <button
                        disabled={id === undefined ? 'disabled' : ''}
                        className='gn-btn-icononly me-2'
                        aria-controls={openMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleClick}>
                        <span className='text-secondary'>
                          <AddIcon />
                        </span>
                      </button>

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        // onClick={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                          sx: {
                            maxWidth: '500px',
                          },
                        }}
                      >
                        <Paper sx={{ width: 200, boxShadow: 'none' }}>
                          <MenuList>
                            <MenuItem onClick={() => { handleClose(); handleDrawerOpen(id); }}>
                              <ListItemIcon>
                                <img src={platform} alt='platform' />
                              </ListItemIcon>
                              <Typography variant="inherit" href="#" >Add Platform</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => { handleOpenPlaylist() }}>
                              <ListItemIcon>
                                <img src={playlist} alt='playlist' />
                              </ListItemIcon>
                              <Typography variant="inherit" >Add Playlist</Typography>
                            </MenuItem>
                          </MenuList>
                        </Paper>
                      </Menu>
                      <CreatePlaylistModal openPlaylist={openPlaylist} handleOpenPlaylist={handleOpenPlaylist} closeCreateModal={handleClose} songData={data} userId={userData && userData.id} songId={data.id} />
                      <label className="switch">
                        <input disabled={disableViewSatus} checked={visiblityStatus} type="checkbox" id={data.id} role="switch" onChange={(e) => combineChangeHanddler(e)} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>

                  {/* link song title */}
                  <div className="link-input-fields bg-white p-0">
                    <div className="w-50 me-1 bg-light rounded">
                      <EditText name="song-title"
                        className="p-2 w-100"
                        defaultValue={songTitle} onSave={songTitleChangeHandler} placeholder="Song title" />
                      {errorSongTitle && <div className={`custom-tooltip`}>
                        <span className="tooltip-text">{errorSongTitle}</span>
                        <span className="top"></span>
                      </div>}
                    </div>
                    <div className="w-50 ms-1 bg-light rounded">
                      <EditText name="song-title"
                        className="p-2 w-100"
                        defaultValue={data?.slug} onSave={songSlugChangeHandler} placeholder="Song slug" />
                    </div>
                  </div>
                  {/* link tags */}
                  <div className="link-input-fields  bg-light">
                    <div className="w-100">
                      <Autocomplete
                        multiple
                        size="small"
                        id={`tags${id}`}
                        limitTags={1}
                        options={songTagsList ? songTagsList.length > 0 ? songTagsList.map((option) => option.tagName) : [] : []}
                        value={selectedTags}
                        onChange={songTagsChangeHandler}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip variant="filled" size="small" label={option} deleteIcon={<CancelIcon style={{ color: '#333' }} />} {...getTagProps({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            error={errorTags}
                            /* helperText={tagHelperText} */
                            size="small"
                            sx={{ padding: '3px 0px' }}
                            placeholder="Tag"
                            className={`removeBorder`}
                            color="secondary"
                          />
                        )}
                      />
                      {errorTags && <div className={`custom-tooltip`}>
                        <span className="tooltip-text">{tagHelperText}</span>
                        <span className="top"></span>
                      </div>}
                    </div>
                  </div>
                  {/* action button container:start */}
                  <div className="action-btn-container">
                    <div className="inner-container" style={{ display: 'flex' }}>
                      <div className='link-action-btns'>

                      </div>
                      <div>
                        <div>
                          {/* <button className='gn-btn-icononly' href="#"> <span onClick={() => deleteSongHandler(id,index)}> <DeleteIcon /></span></button> */}
                          <button title="Delete" className='gn-btn-icononly text-secondary' href="#"> {data && data.id !== undefined ? <span id={data.id} onClick={() => ref.current.handleOpen()}> <DeleteIcon /></span> : <span id={data.id} onClick={() => deleteSongHandler(id, index)} className='text-secondary'> <DeleteIcon /></span>}</button>
                        </div>
                      </div>
                    </div>
                  </div>{/* action button container:end */}
                </div> {/* Link wrapper ends here */}

              </div>{/*  link input close */}
              {/*  </form> */}
              {/* Belwo action buttons */}

            </div>
          </div>
          {/* Delete -> section */}
          <DeleteModal cardId={data && data.id} deleteHandler={deleteSongHandler} ref={ref} />
        </div>
      </div>

      {/* Drawer for add platofrom */}
      <Drawer
        sx={{
          width: '500px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: smallViewport ? '100%' : '500px',
            boxSizing: 'border-box',
            backgroundColor: '#F2EDED'
          },
        }}
        anchor="right"
        open={open}
      >
        <div>
          <div className="p-2 shadow d-flex justify-content-between align-items-center" style={{ backgroundColor: '#F6F6F6' }}>
            <div><h5>Add platform</h5></div>
            <div style={{ position: 'relative', top: '-5px' }}><button title="Close" className="icononly-btn" onClick={handleDrawerClose}><CloseIcon /></button></div>
          </div>
          <div className="p-4">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className='gn-actionbtn' disabled={disableAddNewPlatform || allPlatfromList?.length == 0 ? 'disabled' : ''} onClick={handleAddNewPlatform}>ADD NEW</button>
            </div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} className="">
                    <StreamingPlatformContext.Provider value={{ allPlatfromList, handleAllPlatformList, allPlatfromListCopy }}>
                      {open && relatedPlatform.map((platform, index) => {
                        return (
                          <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided) => (
                              <React.Fragment key={platform.id}>
                                <AddPlatforms key={platform.id}
                                  deletePlatformHandler={deletePlatformHandler}
                                  reRenderPlatformHandler={reRenderPlatformHandler}
                                  addNewDisabledHandler={addNewDisabledHandler}
                                  cardData={platform}
                                  songId={id}
                                  cardId={platform.id}
                                  index={index}
                                  allRelatedPlatforms={relatedPlatform}
                                  provided={provided}
                                />
                              </React.Fragment>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </StreamingPlatformContext.Provider>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        {/* {renderPlatForms(id,handleDrawerClose,platformsList)} */}
      </Drawer>


      <div
        ref={bodyRef}
        tabIndex={-1}
        style={{ position: 'absolute', left: '-9999px' }}
      ></div>
    </>
  )
}

export default AddSong