import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { getGenre, getLanguage } from '../../../../_services/profile';
import { moodRequest } from '../../../../_services/profile/mood.service';
import { useSelector } from 'react-redux';
import { validateISRC } from './helperUPCISRC';

export default function AddMusicDetailsModal({ data, handleCloseMenu, setOpenMusicModal, compareId, openMusicModal, updateSong, createSong }) {
    const userData = useSelector((state) => state.userData.userData);
    const [genre, setGenre] = useState([])
    const [moodList, setMoodList] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [errors, setErrors] = useState({});
    const [musicDetailData, setMusicDetailData] = useState({
        genre: data?.genre || "",
        mood: data?.mood || "",
        language: data?.language || "",
        artists: data?.artists || '',
        isrc: data?.isrc || ''
    })
    const SelectField = [
        { id: "genre", label: "Select Genre", value: musicDetailData?.genre || '', onChange: onChangeHandler, name: 'genre', listData: genre },
        { id: "mood", label: "Select Mood", value: musicDetailData?.mood || '', onChange: onChangeHandler, name: 'mood', listData: moodList },
        { id: "language", label: "Select Language", value: musicDetailData?.language || '', onChange: onChangeHandler, name: 'language', listData: languageList },
    ]
    const inputField = [
        { id: "artists", label: "Artist Name", value: musicDetailData?.artists || '', onChange: onChangeHandler, name: 'artists', requiredField: true },
        { id: "isrc", label: "ISRC", value: musicDetailData?.isrc || '', onChange: onChangeHandler, name: 'isrc' },
    ]

    useEffect(function () {
        getGenre().then(function (response) {
            setGenre(response.data.data)
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
        });

        getLanguage().then(function (response) {
            setLanguageList(response.data ? response.data.data : "")
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
        });
        moodRequest()
            .then((response) => {
                setMoodList(response.data.data)
            })
            .catch((error) => {
                return error.response;
            })
    }, []);

    function onChangeHandler(e) {
        const { name, value } = e.target;
        setMusicDetailData({
            ...musicDetailData,
            [name]: value
        });
    }

    const validateFields = () => {
        const newErrors = {};
        inputField.forEach(field => {
            const isrcValidation = validateISRC(field.value);
            if (field.requiredField && !musicDetailData[field.name]) {
                newErrors[field.name] = `${field.label} is required`;
            } else if (field.name === 'isrc' && isrcValidation.isValid) {
                newErrors[field.name] = isrcValidation.errorMessage;
            }
        });
        return newErrors;
    };

    function onSaveCredits() {
        const newErrors = validateFields();
        if (Object.keys(newErrors).length === 0) {
            handleClose()
            userData.id && compareId == undefined ? createSong({ musicDetailData }) : updateSong({ musicDetailData })
        } else {
            setErrors(newErrors);
        }
    }

    function handleClose() {
        setErrors({});
        setOpenMusicModal(false)
        handleCloseMenu()
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openMusicModal}>
            <DialogTitle>Music Details</DialogTitle>
            <DialogContent>
                {/* -------------------- Artist,Genre, Mood and Language ---------------------- */}
                <Grid container spacing={2}>
                    {inputField.map((d, i) => (
                        <Grid key={i} item xs={6}>
                            <TextField
                                className='w-100 my-3'
                                key={d.id}
                                id={d.id}
                                name={d.name}
                                label={d.label}
                                value={d?.value}
                                defaultValue={d?.value}
                                onChange={d.onChange}
                                error={!!errors[d.name]}
                                helperText={errors[d.name]}
                                required={d.requiredField}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={2}>
                    {SelectField.map((d, i) => (
                        <Grid key={i} item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id={d.id}>{d.label}</InputLabel>
                                <Select
                                    labelId={d.id}
                                    id={d.id}
                                    name={d.name}
                                    label={d.label}
                                    value={d.value}
                                    onChange={d.onChange}
                                >
                                    {d?.listData.map(v => {
                                        const displayValue = d.name === "genre" ? v.genre : d.name === "language" ? v.language : d.name === "mood" ? v.mood : '';
                                        const displayId = d.name === "genre" ? v.id : d.name === "language" ? v.id : d.name === "mood" ? v.id : '';
                                        return <MenuItem key={displayId} value={displayId}>{displayValue}</MenuItem>;
                                    })}

                                </Select>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='text' sx={{ color: "#000" }} onClick={handleClose} >Cancel</Button>
                <Button className='gn-actionbtn' onClick={onSaveCredits}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
