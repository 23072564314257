import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PlaylistCard from './PlaylistCard';
import PlaylistDrawer from './PlaylistDrawer';
import { Link } from 'react-router-dom';
import SnackbarContainer from '../../../Snackbar';
import { getAllPlaylistOfUser } from '../../../../_services/playlist/getAllPlaylistOfUser';
import CardSekeleton from '../../../../components/reusableComponents/SkeletonCard';
import NoResults from '../../../../components/reusableComponents/NoResults';

function AllPlaylist(props) {
  const userData = useSelector((state) => state.userData.userData);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [playlistList, setPlaylistList] = useState([]);
  const numberOfSkeletonCards = 12
  const skeletonCards = Array.from({ length: numberOfSkeletonCards }, (_, index) => (
    <CardSekeleton key={index} />
  ));

  React.useEffect(function () {
    if (userData.id != undefined) {
      getPlaylistList()
    }
  }, [userData.id])

  function getPlaylistList() {
    setSkeletonLoader(true)
    getAllPlaylistOfUser({ search: userData.id && userData.id, limit: 25, offset: 0 })
      .then(function (response) {
        setSkeletonLoader(false)
        setPlaylistList(response.data.results)
        console.log(response.data.results)
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  const breadcrumbs = [
    // { label: 'Song Hub', link: '/songhub' },
    { label: 'Dashboard', link: '/dashboard' },
    { label: 'Playlist', link: '/playlist' },
  ];

  const addPlaylist = (newPlaylist) => {
    //to rerender the list
    getPlaylistList()
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <div className="row m-0">
        <div className="col-12">
          <div>
            <h2>Song Hub</h2>
            <div className="d-flex">
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={breadcrumb.label}>
                  {index > 0 && <div style={{ width: '20px', marginLeft: '10px' }} className='text-secondary'>◆</div>}
                  <div>
                    <Link to={breadcrumb.link} style={{ textDecoration: 'none', color: 'gray', fontSize: '0.8rem' }}>
                      {breadcrumb.label}
                    </Link>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className='pt-4'>
              <a target="_blank" href={`${process.env.REACT_APP_CREATORURL}${userData && userData.userHandle}/playlist`}>{`${process.env.REACT_APP_CREATORURL}${userData && userData.userHandle}/playlist`}</a>
            </div>
          </div>
          <>
            <div className="my-3">
              <button className="gn-actionbtn" onClick={openDrawer}>
                CREATE PLAYLIST
              </button>
              <div className="pt-4">
                {skeletonLoader ?
                  <div className="wrapper-card">
                    {skeletonCards}
                  </div> :
                  playlistList.length > 0 ?
                    <div className="row m-0">
                      {playlistList.map((playlist, index) => (
                        <div className='col-md-4 col-sm-6 col-xs-12 col-lg-3 my-2'>
                          <PlaylistCard
                            key={playlist.playlist_id}
                            playlistInfo={playlist}
                          />
                        </div>
                      ))}</div> :
                    <NoResults />
                }
              </div>
            </div>
            <PlaylistDrawer
              isOpen={isDrawerOpen}
              onClose={closeDrawer}
              onCreatePlaylist={addPlaylist}
            />
          </>
        </div>
      </div>
      <SnackbarContainer />
    </div>
  );
}

export default AllPlaylist;
