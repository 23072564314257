import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { setLoader } from '../../redux';
import TextField from '@mui/material/TextField';
import '../../admin/style.css'
import useMediaQuery from '@mui/material/useMediaQuery';
import { postMetapixel } from '../../_services/profile/Metapixel/postMetapixel.service';
import { updateMetapixel } from '../../_services/profile/Metapixel/createMetapixel.service';
import { updateProfile } from '../../_services/profile';
import { getBannerList } from '../../_services/profile/getBannerList.service';
import { Box, FormControl, FormControlLabel, ImageList, ImageListItem, Radio, RadioGroup } from '@mui/material';
import DynamicToggleTabs from './dynamicToggleButton';

function Setting(props) {
  const smScreen = useMediaQuery('(max-width:600px)');
  const userData = useSelector((state) => state.userData.userData);
  const [selectedFile, setSelectedFile] = useState(null);
  const [bannerList, setBannerList] = useState([])
  const dispatch = useDispatch();
  const [metaPixel, setMetaPixel] = useState()
  const [savebtn, setSavebtn] = useState(true)
  const [fileSizeError, setFileSizeError] = useState(false);
  const [selectedTabValue, setSelectedTabValue] = useState(2)
  const [filePreviewUrl, setFilePreviewUrl] = useState(null)
  /* for setting flag and code with dropdown */

  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (event) => {
    console.debug("handleChange:handleChange", event.target)
    setSelectedValue(event.target.value);
  };

  const fileInputRef = useRef(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes



  useEffect(() => {
    console.debug("selectedFile:gjjhhjvjh", userData)
    if (userData.banner_img) {
      setSelectedFile(userData?.banner_img)
    } else {
      if (userData.bannerimg) {
        setSelectedValue(userData?.bannerimg?.id)
      }
    }
    getBannerList().then(function (response) {
      if (response.data) {
        let r = response.data?.data
        console.debug("userData:response", response)
        setBannerList(r)
      }
    })

  }, [userData])

  const fileValidation = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    if (file && fileType.startsWith('image/')) {
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.height === 396 && img.width === 1440) {
            if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
              dispatch(setSnackBar(true));
              dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
              dispatch(setSnackBarVariant('error'));
              setTimeout(() => dispatch(setSnackBar(false)), 3000)
              return false
            }
            else {
              if (file.size > 50000000) {
                dispatch(setSnackBar(true));
                dispatch(setSnackBarMessage(formSnackbar.errors.bannerUploadSizeImage));
                dispatch(setSnackBarVariant('error'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000)
              } else {
                setSelectedFile(file);
                console.debug("sjbksbkjkbjs", reader)
                setFilePreviewUrl(reader.result);

                return true
              }
            }
          } else {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.bannerUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            return false
          }
        }
      }

    } else {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return false
    }
  }

  function updateBannerImage(e) {
    e.preventDefault();
    let formData = new FormData()

    if (selectedTabValue == 1) {
      formData.append('banner_img', selectedFile)
      formData.append('bannerimg', '')
    } else {
      if (selectedTabValue == 2) {
        formData.append('bannerimg', selectedValue)
        formData.append('banner_img', '')
      }
    }

    dispatch(setLoader(true))
    updateProfile(formData, userData.id).then(response => {
      if (response) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('success'))
        dispatch(setSnackBarMessage(formSnackbar.POST_PROFILE_UPDATE))
        dispatch(setLoader(false))

        setTimeout(function () {
          dispatch(setSnackBar(false))
        })
      }
    })

  }

  console.debug("BannerData", userData)

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileValidation(file)
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      fileValidation(file)
    }
  };

  const handleTabSelect = (newValue) => {
    setSelectedTabValue(newValue)
    console.debug("handleTabSelect", newValue)
  };

  return (
    <div>
      <div className='d-flex justify-content-between p-4'>
        <h2>Cover Image</h2>
        {userData.enable_distribution == true || userData?.premium_features == true ? 
        <Box className='d-flex align-items-center gap-5' >
          <DynamicToggleTabs onSelectTab={handleTabSelect} />
        </Box> : ""
        } 
        </div>

      {selectedTabValue == 2 ?
        <FormControl component="fieldset" className='m-4'>
          <ImageList cols={3} gap={8} style={{ overflow: "hidden" }}>
            {bannerList.map((item, index) => (
              <ImageListItem key={index} style={{ position: 'relative' }}>
                <img src={process.env.REACT_APP_BASEURL_MEDIA + '/media/' + item.banner} alt="banner" style={{maxHeight: '91px', borderRadius:"4px"}} />
                <Radio
                  value={item.id}
                  checked={selectedValue == item.id}
                  onChange={handleChange}
                  size='large'
                  name="radio-buttons"
                  className="radioButtonBanner"
                  style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '20px',
                    width: '7%',
                    height: '23%'
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </FormControl> :
        selectedTabValue == 1 ? <>
          <div
            className="image-uploader m-4"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none', }}
              ref={fileInputRef}
            />
            <div onClick={() => fileInputRef.current.click()} className="audio-uploader custom-height ">
              <p className='text-primary'><UploadFileIcon style={{ height: '1rem', width: '1rem' }} /></p>
              <div className='d-flex'>
                <div style={{ marginRight: '10px' }}>
                  <span className='text-primary'>Click to upload</span>
                </div>
                <div>
                  <span>or drag and drop *</span>
                </div>
              </div>
              <p className='text-secondary' style={{ fontSize: '0.8rem' }}>JPG, JPEG, PNG (max. 50MB) with 1440x396 pixels</p>
            </div>
            <div className="text-danger">
              {/* {fileError} */}
            </div>
            {(selectedFile != undefined || selectedFile != null) && (
              <div className=" mt-3 mb-3" style={{ display: 'flex', justifyContent: 'center' }}>

                <img src={userData && (userData.banner_img == null || userData.banner_img != selectedFile) ? URL.createObjectURL(selectedFile) : process.env.REACT_APP_BASEURL_MEDIA + '/media/' + selectedFile} alt="Selected" style={{ width: '30rem', height: '7rem' }} />
              </div>
            )}
          </div>:</> : ""}
      <div className='mx-4'>
        <button title="Save Meta Pixel"
          className='gn-actionbtn float-end'
          onClick={updateBannerImage}
        // disabled={savebtn}
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default Setting;