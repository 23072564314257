
import React, { useEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import { addExperienceService } from '../../_services/profile/addExperience.service'
import { updateExperience } from '../../_services/profile/updateExperience';
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserDetail, setLoader } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setSnackBar, setSnackBarMessage,setSnackBarVariant } from '../../redux';
import { Drawer, FormControl, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import Select from "@mui/material/Select";
import {  InputLabel } from '@mui/material';
import {  getCountry} from '../../_services/profile'
import OutlinedInput from '@mui/material/OutlinedInput';


function AddExperience(props) {
    const dispatch = useDispatch();
    const smallViewport = useMediaQuery('(max-width:768px)');
    const [companyName, setCompanyName] = useState();
    const [companyNameErr, setCompanyNameErr] = useState("");
    const [location, setLocation] = useState();
    const [locationErr, setLocationErr] = useState("");
    const [jobTitle, setJobTitle] = useState('');
    const [jobTitleErr, setJobTitleErr] = useState(false);
    const [countryErr, setCountryErr] = useState("");
    const [country, setCountry] = useState();
    const [description,setDescription]=useState();
    const [startingYear,setStartingYear]=useState(null);
    const[endYear, setEndYear] = useState(null);
    const[startDateErr, setStartDateErr] = useState();
    const [startDateErrStatus, setStartDateErrStatus] = useState(false)
    const [endYearErr, setEndYearErr] = useState();
    const [endDateErrStatus, setEndDateErrStatus] = useState(false)
    const [countryList, setCountryList] = useState();
    const userData = useSelector((state) => state.userData.userData);
	const mediumViewport = useMediaQuery('(max-width:720px)');
    let style
		mediumViewport ? 
        style = 
        {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
        }: 
        style = 
        {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
        };
  
    useEffect(()=>{
        getCountry().then(function (response) 
        {
            setCountryList(response.data ? response.data.data : "")
        }).catch(function (error) 
        {
            dispatch(setLoader(false))
            console.log(error);
            return error.response;
        })
       
    },[]) 
      
    const StateUpdate = () =>{
            setCompanyName(props.currentRowData.companyName)
            setLocation(props.currentRowData.location)
            setJobTitle(props.currentRowData.title)
            setCountry(props.currentRowData.country)
            setDescription(props.currentRowData.description)
            setEndYear(props.currentRowData.endYear)
            setStartingYear(props.currentRowData.startingYear)
    }

    useEffect(()=>{
        if(props.actionType ==='edit'){
            props.currentRowData && StateUpdate();
        }
    },[props.currentRowData])   
    
    const handleClose = () => {
        props.setOpen(false)
        props.setCurrentRowData(null)
        //set all states to null on close
        setCompanyName(null)
        setLocation(null)
        setJobTitle(null)
        setCountry(null)
        setDescription(null)
        setEndYear(null)
        setStartingYear(null)
        setCompanyNameErr(false)
        setLocationErr(false)
        setJobTitleErr(false)
        setCountryErr(false)
        setStartDateErrStatus(false)
        setEndDateErrStatus(false)
    };  

    function saveExperienceLevel(e) {
        e.preventDefault();
        var postData = {}
        if (companyName) 
        {
            postData['companyName'] = companyName
        }
        else
        {
            setCompanyNameErr(<div>
                <span className='text-danger'>Please Enter Event Name</span>
            </div>);
        }
        if (location) 
        {
            postData['location'] = location
        }
        else
        {
            setLocationErr(<div>
                <span className='text-danger'>Please Enter City</span>
            </div>);
        }
        if(jobTitle)
        {
            postData['title'] = jobTitle
        }
        else
        {
            setJobTitleErr(<div>
                <span className='text-danger'>Please Enter Job Title</span>
            </div>);
        }
        if (country) 
        {
            postData['country'] = country
        }
        else
        {
            setCountryErr(<div>
                <span className='text-danger'>Please Enter Country</span>
            </div>);
        }
        if (!startDateErr)
        {
            setStartDateErrStatus(false)
            postData['startingYear'] = startingYear
        }
        else
        {   
            setStartDateErrStatus(true)
            return;
        }
        if (!endYearErr){
            setEndDateErrStatus(false)
            postData['endYear'] = endYear
        }
        else
        {   setEndDateErrStatus(true)
            return;
        }
        if (description)
        {
            postData['description'] = description
        }
        postData['user'] = userData.id; 
        if(companyName && location && country && jobTitle )
        {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('info'))
        dispatch(setSnackBarMessage(formSnackbar.PRE_EXPERIENCE_UPDATE))
        dispatch(setLoader(true))
        if(props.actionType==='edit')
        {   let expeId = props.currentRowData.id
            updateExperience(postData,expeId).then(function (response) 
            {
                if(response.status === 200)
                {
                    dispatch(setLoader(false))
                    dispatch(setSnackBarVariant('success'))
                    dispatch(setSnackBarMessage(formSnackbar.POST_EXPERIENCE_UPDATE))
                    setTimeout(() =>  dispatch(setSnackBar(false)), 2000)
                    handleClose();
                    props.setOpen(false)
                    props.getExperienceHandler();
                    dispatch(fetchUserDetail())
                }
                else
                {
                    dispatch(setLoader(false))
                    dispatch(setSnackBarVariant('error'))
                    dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                    setTimeout(() =>  dispatch(setSnackBar(false)), 3000)
                }
            })
        }
        else
        {
            addExperienceService(postData,userData.id).then(function (response) 
                {
                    if(response.status === 200)
                    {
                        dispatch(setLoader(false))
                        dispatch(setSnackBarVariant('success'))
                        dispatch(setSnackBarMessage(formSnackbar.POST_EXPERIENCE_UPDATE))
                        setTimeout(() =>  dispatch(setSnackBar(false)), 2000)
                        handleClose();
                        props.setOpen(false)
                        props.getExperienceHandler();
                        dispatch(fetchUserDetail())
                    }
                    else
                    {
                        dispatch(setLoader(false))
                        dispatch(setSnackBarVariant('error'))
                        dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                        setTimeout(() =>  dispatch(setSnackBar(false)), 3000)
                    }
                })
        }
    }
}
    return (
            <Drawer
                sx={{
                width: '400px',
                flexShrink: 0,'& .MuiDrawer-paper': 
                {
                width: smallViewport?'300px':'400px',
                boxSizing: 'border-box',
                backgroundColor:'#ffffff'
                },
                }}
                anchor="right"
                open={props.open}
                onClose={handleClose}
            >  
                <div>
                    <div className="p-2  d-flex justify-content-between align-items-center" style={{backgroundColor:'rgb(248 248 248)'}}>
                        <div style={{marginLeft:'16px'}}><h4>Experience</h4></div>
                        <div style={{position:'relative',top:'-5px'}}>
                            <button title="Close" className="icononly-btn" onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        </div> 
                    </div>
                    <div className="p-4">
                            <div>
                                <form>
                                    <h5 className='mt-2'>{props.currentRowData && props.currentRowData.id?'Edit':'Add'} Employment</h5>
                                    <div className='mb-3 mt-2'>
                                            <TextField 
                                                size='small'
                                                value={companyName}
                                                error={companyNameErr}
                                                onChange={(e)=>setCompanyName(e.target.value)?setCompanyNameErr(true):setCompanyNameErr(false)}
                                                name="companyname"
                                                label="Company Name/Event Name*" variant="outlined" className="w-100 rounded mt-2" />
                                    </div>
                                   
                                    <div className='mb-3'>
                                        <TextField 
                                            value={location}
                                            size='small'
                                            error={locationErr}
                                            onChange={(e) => {setLocation( e.target.value)?setLocationErr(true):setLocationErr(false);} }
                                            name="Location"
                                            label="Location*" variant="outlined" className="w-100 rounded mt-2" />
                                    </div>
                                    <div className='mb-3'>
                                            <FormControl sx={{marginTop:'10px'}} fullWidth error={countryErr} size='small' >
                                                <InputLabel id="countryID">Country*</InputLabel>
                                                    <Select
                                                        labelId="countryID"
                                                        value={country}
                                                        onChange={(e) => {setCountry( e.target.value)?setCountryErr(true):setCountryErr(false);} }
                                                        input={<OutlinedInput label="Country*" />}
                                                        MenuProps={{
                                                            style: {
                                                            maxHeight: 250,
                                                                },
                                                            }}
                                                    >
                                                    {countryList ? countryList.map(data => (

                                                      data.country_code &&  <MenuItem key={data.id}value={data.id}>{data.country_name}</MenuItem>
                                                    )) :null}
                                                    </Select>
                                            </FormControl>
                                    </div>
                                    <div className='mb-3'>
                                            <TextField 
                                                value={jobTitle}
                                                size='small'
                                                error={jobTitleErr}
                                                onChange={(e) => {setJobTitle( e.target.value)?setJobTitleErr(true):setJobTitleErr(false)}}
                                                name="Title"
                                                label="Job Title*" variant="outlined" className="w-100 rounded mt-2" />
                                              
                                    </div>
                                    <div className='mb-3'>
                                        <TextField
                                        size='small'
                                        id="description"
                                        label="Description"
                                        multiline
                                        rows={4}
                                        value={description}
                                        onChange={(e)=>setDescription(e.target.value)}
                                        sx={{width:'100%'}}
                                        />
                                    </div>
                                    <h6 className='mt-4 mb-3'>Time Period</h6>
                                    <div className='mb-3'>
                                    <LocalizationProvider 
                                        dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                                views={['year', 'month','day']}
                                                label="Start Date"
                                                maxDate={dayjs(endYear)}
                                                value={startingYear}
                                                onError={(newValue)=>{
                                                    setStartDateErr(newValue)
                                                    newValue?setStartDateErrStatus(true):setStartDateErrStatus(false)
                                                }}
                                                onChange={(newValue) => {
                                                    setStartingYear(newValue);
                                                }}
                                                renderInput={(params) => <TextField size='small' sx={{minWidth:"100%",backgroundColor:'#F6F6F6',mt:1}} {...params} error={startDateErrStatus} helperText={startDateErr} />}
                                                />
                                    </LocalizationProvider>
                                    </div>
                                    <div className='mb-3'>          
                                        <LocalizationProvider 
                                            dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                views={['year', 'month','day']}
                                                label="End Date"
                                                value={endYear}
                                                maxDate={dayjs()}
                                                onError={(newValue)=>{
                                                    setEndYearErr(newValue)
                                                    newValue?setEndDateErrStatus(true):setEndDateErrStatus(false)
                                                }}
                                                onChange={(newValue) => {
                                                setEndYear(newValue);
                                                }}
                                                renderInput={(params) => <TextField size='small' sx={{minWidth:"100%",backgroundColor:'#F6F6F6',mt:1}} {...params} error={endDateErrStatus} helperText={endYearErr}/>}
                                            />
                                        </LocalizationProvider>
                                    </div>  
                                    <div className='mt-4 d-flex justify-content-end'>
                                        <button onClick={saveExperienceLevel} className='gn-actionbtn' >Submit</button>
                                    </div>
                                </form>
                            </div>
                    </div>    
                </div>    
        </Drawer> 
    );
}

export default AddExperience;