export const formSnackbar = {
  PRE_CATEGORY_UPDATE: 'category updating',
  POST_CATEGORY_UPDATE: 'category updated successfully',
  PRE_GENRE_UPDATE: 'Genre updating',
  POST_GENRE_UPDATE: 'Genre updated successfully',
  PRE_PROFILE_UPDATE: 'Profile updating',
  POST_PROFILE_UPDATE: 'Profile updated successfully',
  PRE_PHONE_UPDATE: 'Phone number updating',
  POST_PHONE_UPDATE: 'Phone number updated successfully',
  PRE_SOCIAL_UPDATE: 'Social data updating',
  POST_SOCIAL_UPDATE: 'Social data updated successfully',
  PRE_SOCIAL_DELETE: 'Social data Deleting',
  POST_SOCIAL_DELETE: 'Social data Deleted successfully',
  PRE_USERHANDLE_UPDATE: 'Profile updating',
  POST_USERHANDLE_UPDATE: 'Profile updated successfully',
  PRE_EXPERIENCE_UPDATE: 'Experience  updating',
  POST_EXPERIENCE_UPDATE: 'Experience updated successfully',
  PRE_EXPERIENCE_DELETE: 'Experience  deleting',
  POST_EXPERIENCE_DELETE: 'Experience deleted successfully',
  PRE_CREATER_DELETE: 'Link  deleting',
  POST_CREATER_DELETE: 'Link deleted successfully',
  PRE_CREATER_LINK_UPDATE: ' Creator link  updating',
  POST_CREATER_LINK_UPDATE: 'Creator link updated successfully',
  PRE_REGISTER_MSG: 'User registering......',
  POST_REGISTER_MSG: 'User registered successfully',
  creatorLink: {
    linkCreate: 'Creating link...',
    linkCreateSuccess: 'Creator link created successfully',
    linkUpdate: 'Updating link...',
    linkUpdateSuccess: 'Creator link updated successfully',
    linkDelete: 'Deleting link...',
    linkDeleteSuccess: 'Creator link deleted successfully'
  },
  errors: {
    alreadyRegistered: 'User with this email id is already registered',
    errorMsg: 'Something went wrong! Your request has failed.',
    urlError: 'Please enter a valid URL. Ex. `http://www.domain.com` ',
    imgUploadSize: '"Sorry, the dimensions of the image you uploaded are incorrect. Please upload an image that is 3000 pixels wide and 3000 pixels high."',
    bannerUploadSize: '"Sorry, the dimensions of the image you uploaded are incorrect. Please upload an image that is 1440 pixels wide and 396 pixels high."',
    imgUploadType: "Oops, it looks like you've uploaded the wrong file format. Please choose a valid .jpg or .png file.",
    fileUploadType: "Oops, it looks like you've uploaded the wrong file format. Please choose a valid .wav file.",
    fileUploadSize: "Sorry, the file size you uploaded is too large. Please choose a file that is under 50MB in size.",
    fileUploadSizeImage: 'Please choose a file that is under 10MB in size.',
    bannerUploadSizeImage: 'Please select a file that is between 40MB to 50MB in size.',
    fileUploadTypeDemo: "Oops, it looks like you've uploaded the wrong file format. Please choose a valid .wav or .mp3 file.",
    fileUploadSizeDemo: 'Please choose a file that is under 20MB in size.',
    characterCountErrorlow: 'Maximum 100 characters are allowed',
    characterCountErrorMax: 'Maximum 1000 characters are allowed',
    lyricscharacterCountErrorMax: 'Maximum 5000 characters are allowed',
    characterCountErrorMin: 'Minimum 100 characters are allowed',
    characterCountErrorMaxOnek: 'Maximum 1000 characters are allowed',
    validInput: 'Please enter a valid input',
    imgUploadAvatarSize: "Please upload an image that is between 200px and 300px wide and between 200px and 300px tall.",
    imgTypeAvatar: "Oops, it looks like you've uploaded the wrong file format. Please choose a valid .jpg or .png file.",
    invalidCredentials: 'Invalid login credentials.',
    validPassword: 'Please enter a valid password',
    validEmail: 'Please enter a valid email',
    // tanya code start
    isrcAndUpcLength: 'Maximum length 12 characters ',
    noChangeFound: 'No changes found',
    spotifyIdExample: 'Please enter valid ID. Ex. spotify:artist:6rsayhvnmerydbDhG6',
    appplyIdError: 'Apple Id should contain digits only.',
    socialPlatformHandle: 'Please enter valid data. Ex.@example',
    characterError: 'Maximum 255 characters are allowed',
    largeFileLimitError: 'File size exceeds the allowed limit of 250MB.',
    fileTypeUploadError: 'Invalid file format. Only .WAV files are allowed.',
    InvalidGNID: 'Please enter a valid GNID.',

    // end

  },
  verifyArtist: {
    verficationApply: 'Applying for verification',
    verificationApplied: 'Successfully applied for verification',
    verificationPending: 'Your account verification is pending',
    verificationRejected: 'Your application to verify your account has been rejected'
  },
  serviceRequest: {
    serviceRequestSuccess: 'Success',
    serviceRequestFailure: 'The service has responded with the error',
    videoRequestFailure:'Enter a valid video Url'
  },
  socialLink: {
    socialLinkCreate: 'Creating social link',
    socialLinkSuccess: 'Social link created successfully',
    socialLinkUpdate: 'Updating social link',
    socialLinkUpdateSuccess: 'Social link updated successfully',
    socialLinkDelete: 'Deleting social link',
    socialLinkDeleteSuccess: 'Social link Deleted successfully',
  },
  songPlaylist: {
    songCreate: 'Creating song link',
    songCreateSuccess: 'Song link created successfully',
    songLinkUpdate: 'Updating song link',
    songLinkUpdateSuccess: 'Song link updated successfully',
    songLinkDelete: 'Deleting song link',
    songLinkDeleteSuccess: 'Song link deleted successfully',
    songSlugUpdate: 'Updating song slug',
    songSlugUpdateSuccess: 'Explore the latest updates on your profile by clicking on the provided link'
  },
  relatedPlatform: {
    realtedPlatformCreate: 'Adding streaming platform',
    realtedPlatformCreateSuccess: 'Streaming platform added successfully',
    realtedPlatformUpdate: 'Updating streaming platform',
    realtedPlatformUpdateSuccess: 'Streaming platform updated successfully',
    relatedPlatformDelete: 'Deleting streaming platform',
    relatedPlatformDeleteSuccess: 'Streaming platform deleted successfully'
  },
  expertiseLink: {
    expertiseLinkUpdate: 'Expertise updating',
    expertiseLinkUpdateSuccess: 'Expertise updated successfully',
  },
  userlanguage: {
    languageUpdate: 'Updating language',
    languageUpdateSuccess: 'language updated successfully'
  },
  userAddress: {
    userAddressUpdate: 'Updating address',
    userAddressUpdateSuccess: 'Address updated successfully',
  },
  enquireForm: {
    enquireFormDelete: 'Enquiry Deleting',
    enquireFormDeleteSuccess: 'Enquiry deleted successfully',
  },
  submitAsong: {
    ReleaseInfoCreate: 'Submitting your song details...',
    ReleaseInfoCreateSuccess: 'Your song has been submitted successfully',
    SongInfo: 'Deleting your song...',
    SongInfoSuccess: 'Song has beed deleted successfully',
    SongDeleteSuccess: 'Song has been deleted successfully'
  },

  submitDemo: {
    DemoUpload: 'Submitting your demo...',
    DemoUploadSuccess: 'Congratulations! Your demo has been submitted successfully',
    DemoDelete: 'Deleting your demo...',
    DemoDeleteSuccess: 'Demo has been deleted successfully',
  },
  associatedArtist: {
    artistUpload: 'Artist adding',
    success: 'Artist added successfully',
    // artistUpdate:'Artist Updating',
    artistUpdateSuccess: 'Artist update successfully',
    // artistDelete:'Artist Deleting',
    artistDeletedSuccess: 'Artist deleted successfully',
    duplicateArtist: 'The artist you entered has already been created. Please try another name.'
  },
  studioAssociates: {
    duplicateEntry: 'You currently have a pending request or an active connection with this studio.',
    connectionRequestSuccess: 'The connection request has been sent'
  },
  playlist: {
    playlistCreate: 'Your playlist has been created',
    playlistDelete: 'Playlist has been deleted successfully',
    playlistUpdate: 'Playlist has been updated successfully',
    songRemove: 'Song has been removed from the playlist.',
    playlistWarning: 'Song has been already added in the playlist'
  },
  metaPixel: {
    post_metaPixelCreate: 'Your Pixel has been added',
    post_metaPixelDelete: 'Your Pixel has been deleted',
    post_metaPixelUpdate: 'Your Pixel has been updated',

  },
  album:{
    albumCreate_Success: "Your album has been created",
    albumUpdate: 'Playlist has been updated successfully',
    songRemove: 'Song deleted from the album successfully.',
    albumDelete:'Album has been deleted successfully.',
    readyForReleaseAlbum:'Album is has been submitted successfully for release '
  }
}
