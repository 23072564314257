import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { setLoader } from '../../redux';
import TextField from '@mui/material/TextField';
import '../../admin/style.css'
import useMediaQuery from '@mui/material/useMediaQuery';
import { postMetapixel } from '../../_services/profile/Metapixel/postMetapixel.service';
import { updateMetapixel } from '../../_services/profile/Metapixel/createMetapixel.service';

function Metapixel(props) {
    const smScreen = useMediaQuery('(max-width:600px)');
    const userData = useSelector((state) => state.userData.userData);
    const dispatch = useDispatch();
    const [metaPixel, setMetaPixel] = useState()
    const [savebtn, setSavebtn] = useState(true)
    /* for setting flag and code with dropdown */
    useEffect(function () {
        if (userData.id) {
            dispatch(fetchLoginUser(userData.id));
            setMetaPixel(userData?.meta_pixel[0])
        }
    }, [userData.id]);

    function onAboutChangeHandler(e) {
        if (metaPixel?.id) {
            const updatedMetaPixel = { ...metaPixel, pixel_code: e.target.value };
            setMetaPixel(updatedMetaPixel);
            setSavebtn(false)
        } else {
            if (e.target.value == "") {
                setSavebtn(true)
            } else {
                setSavebtn(false)
            }
            setMetaPixel(e.target.value);
        }
    }

    function snackbarEnd(message) {
        dispatch(setSnackBarMessage(message))
        dispatch(setSnackBarVariant('success'))
        dispatch(setSnackBar(true))
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        dispatch(setLoader(false))
    }

    function updateMetapixelHandler(e) {
        e.preventDefault();
        if (userData?.meta_pixel.length > 0) {
            let postData = new FormData()
            postData.append("pixel_code", metaPixel.pixel_code)
            dispatch(setLoader(true))
            // snackbarLoad(formSnackbar.metaPixel.pre_metaPixelUpdate)
            updateMetapixel(metaPixel?.id, postData).then(function (response) {
                snackbarEnd(formSnackbar.metaPixel.post_metaPixelUpdate)
            })
        } else {
            var postData = {}
            if (metaPixel) {
                postData['pixel_code'] = metaPixel
            }
            postData['user'] = userData.id
            dispatch(setLoader(true))
            // snackbarLoad(formSnackbar.metaPixel.pre_metaPixelCreate)
            postMetapixel(postData).then(function (response) {
                snackbarEnd(formSnackbar.metaPixel.post_metaPixelCreate)
            })
        }
    }

    return (
        <div>
            <h4 className='mb-2'>Meta Pixel</h4>
            <div className='mt-4'>
                <TextField
                    id={metaPixel?.id}
                    label="Meta Pixel *"
                    multiline
                    rows={4}
                    value={metaPixel?.pixel_code}
                    onChange={onAboutChangeHandler}
                    placeholder='Meta Pixel'
                    sx={{ width: '100%' }}
                    color="secondary"
                    name="metapixel"

                />
            </div>
            <div className='mt-2 mb-5'>
                <button title="Save Meta Pixel"
                    className='gn-actionbtn float-end'
                    onClick={updateMetapixelHandler}
                    disabled={savebtn}
                >
                    Save
                </button>
            </div>
        </div>
    );
}

export default Metapixel;