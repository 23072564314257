import React, { useRef, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ConstructionOutlined, DragIndicator } from "@mui/icons-material";
import { EditText } from 'react-edit-text';
import { postCreaterDetail } from "../../../_services/creater/createrPost.service"
import { postUpdateDetail } from "../../../_services/creater/updateLink.service"
import { postRemoveImage } from "../../../_services/creater/removeImage.service"
import imgThumbnail from "../../../img/thumbnail.png"
import deleteUser from "../../../img/deleteLink.png"
import linkAnlytics from "../../../img/linkAnalytics.png"
import lockLink from "../../../img/lockLink.png"
import addThumbnail from "../../../img/addThumbnail.png"
import setPriority from "../../../img/setPriority.png"
import { useSelector, useDispatch } from 'react-redux'
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import profileImge from '../../../img/profileImage.png'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { formSnackbar } from '../../../_services/_constants/snackbar.constant'
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../redux';
import CloseIcon from '@mui/icons-material/Close';
import AddThumbnail from './AddThumbnail';
import LockLink from './LockLink';
import { fetchUserDetail } from "../../../redux/user/userDataActions";
import { fetchLoginUser } from '../../../redux/loginUser/loginUserActions';
import { getCreaterLink } from "../../../_services/creater/creater.service";
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import DeleteModal from '../../../components/reusableComponents/DeleteModal';

const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
};

export const Card = ({ id, text, index, url, moveCard, data, handleComponentRerender, provided, deleteHandler, linksDataList }) => {
    const [userThumbnai, setUserThumbnai] = useState(imgThumbnail);
    const [statusVal, setStatusVal] = useState(data && data.status === null ? 0 : parseInt(data.status));
    var titleValue = data.name
    var urlValue = data.url
    var type = data.typeId
    var image = data.image
    var status = data.status == null ? 1 : parseInt(data.status)
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const userDetail = useSelector((state) => state.userDetail.userDetail);
    const [allLinks, setAllLinks] = useState([])
    const [disable, setDisable] = useState()
    const [errorText, setErrorText] = useState(null)
    const [errorTitleText, setErrorTitleText] = useState(null)
    const ref = useRef() //to call the child
    const bodyRef = useRef(null);
    const [thumbnailInfo, setThumbnailInfo] = useState({
        file: [],
        filepreview: null,
    });
    const [invalidImage, setinvalidImage] = useState(null);
    let reader = new FileReader();

    useEffect(() => {
        if (userData.id) {
            getCreaterLink(userData.id)
                .then(function (response) {
                    if (response.data) {
                        setAllLinks(response.data ? response.data.data : []);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                });

            if (data) {
                if (data.name != "" && data.name != undefined && data.name != null) {
                    if (data.url != "" && data.url != undefined && data.url != null) {
                        setDisable(false)
                    }
                    else
                        setDisable(true)
                }
                else
                    setDisable(true)
            }
            else
                setDisable(true)

        }

    }, []);

    const validateFields = () => {
        if ((titleValue === '' || titleValue === undefined || titleValue === null) || (urlValue === '' || urlValue === undefined || urlValue === null)) {
            setStatusVal(0)
            setDisable(true)
            status = 0
            return false
        }
        else {
            /*  setStatusVal(1)
             setDisable(false)
             status = 1 */
            return true
        }
    }

    const validateTextInput = (string) => {
        //let rjExprss = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/
        let rjExprss = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/
        let regex = new RegExp(rjExprss);
        if (string !== '') {
            if (string.match(regex)) {
                return false
            } else {
                return true
            }
        }
        else
            return false
    }

    const handleTitleSave = ({ name, value, previousValue, ...rest }) => {
        titleValue = value
        let isValid = validateFields()
        let valid = validateTextInput(value)
        if (valid) {
            setErrorTitleText(null)
            if (isValid) {
                setStatusVal(1)
                setDisable(false)
                status = 1
            }
            id == undefined ? createApi() : updateLink()
        }
        else {
            if (value === '') {
                id == undefined ? createApi() : updateLink()
                setErrorTitleText(null)
            }
            else {
                setErrorTitleText('Please enter a valid link title')
                setDisable(true)
            }
        }
    };

    function checkUrlString(string) {
        let expression =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        let regex = new RegExp(expression);
        try {
            if (string.match(regex)) {
                return true
            } else {
                return false
            }
        }
        catch (err) {
            return false
        }
    }

    function checkUrlProtocol(string) {
        try {
            const url = new URL(string);
            if (url.protocol === 'http:' || url.protocol === 'https:') {

                if (url.hostname.substring(0, 1) !== '.')
                    return { isTrue: true, modifiedURl: url.href }
                else
                    return { isTrue: false, modifiedURl: url.href }
            }
            else {
                return { isTrue: false, modifiedURl: url.href }
            }
        }
        catch (err) {
            return { isTrue: false, modifiedURl: undefined }
        }
    }


    const handleSave = ({ name, value, previousValue }) => {
        urlValue = value
        let isValid = validateFields()
        if (checkUrlString(value)) {
            let validProtocol = checkUrlProtocol(value)
            if (validProtocol.isTrue) {
                setErrorText(null)
                value = validProtocol.modifiedURl
                urlValue = validProtocol.modifiedURl
                if (isValid) {
                    setStatusVal(1)
                    setDisable(false)
                    status = 1
                }
                id == undefined ? createApi() : updateLink()
            }
            else {
                setErrorText('Please enter a valid URL')
                setDisable(true)
            }
        }
        else {
            if (urlValue === '') {
                id == undefined ? createApi() : updateLink()
                setErrorText(null)
            }
            else
                setErrorText('Please enter a valid URL')
            setDisable(true)
        }
    };

    function createApi() {
        let updateData = new FormData();
        if (image) {
            updateData.append('image', image);
        }
        if (titleValue) {
            updateData.append('name', titleValue);
        }
        if (urlValue) {
            updateData.append('url', urlValue);
        }
        if (status) {
            updateData.append('status', status);
        }
        if (linksDataList.length < 1) {
            updateData.append('display_order', '0');
        }
        else {
            updateData.append('display_order', index);
            let linkD = linksDataList.map((item, index) => { return { 'linkId': item.id ? item.id : 'null', 'index': index } })
            updateData.append('linkList ', JSON.stringify(linkD))
        }
        updateData.append('user', userData.id);

        dispatch(setSnackBarMessage(formSnackbar.creatorLink.linkCreate))
        dispatch(setSnackBarVariant('info'))
        dispatch(setSnackBar(true))
        postCreaterDetail(updateData)
            .then(function (response) {
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
                if (response.status === 200) {
                    dispatch(setSnackBarMessage(formSnackbar.creatorLink.linkCreateSuccess))
                    dispatch(setSnackBarVariant('success'))
                    dispatch(fetchUserDetail()); // update the store 
                    handleComponentRerender();
                    bodyRef.current.focus();
                }
                else {
                    if (response.data.name) {
                        dispatch(setSnackBarMessage(response.data.name[0]));
                    } else {
                        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
                    }
                    dispatch(setSnackBarVariant('error'))
                    setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
                }
            }).catch(function (error) {
                dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
                dispatch(setSnackBarVariant('error'))
                setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
            })

    }
    function updateLink() {

        let updateData = new FormData();

        if (typeof image == 'object' && image != null) {
            updateData.append('image', image);
        }
        if (titleValue || titleValue === '') {
            updateData.append('name', titleValue);
        }
        if (urlValue || urlValue === '') {
            updateData.append('url', urlValue);
        }

        updateData.append('status', status);

        //update the current link
        dispatch(setSnackBarMessage(formSnackbar.creatorLink.linkUpdate))
        dispatch(setSnackBarVariant('info'))
        dispatch(setSnackBar(true))
        postUpdateDetail(updateData, id).then(function (response) {
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            if (response.status === 200) {
                dispatch(setSnackBarMessage(formSnackbar.creatorLink.linkUpdateSuccess))
                dispatch(setSnackBarVariant('success'))
                dispatch(fetchUserDetail()); // update the store 
                handleComponentRerender(); //call the parent function to change the local state and rerender the link tree component
                bodyRef.current.focus();
            }
            else {
                dispatch(setSnackBarVariant('error'))
                let responseData = response.data;
                if (responseData.url && responseData.url.length > 0) {
                    dispatch(setSnackBarMessage(formSnackbar.errors.urlError));
                }
                else {
                    dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
                }
                if (responseData.name) {
                    dispatch(setSnackBarMessage(responseData.name[0]));
                }
            }
        }).catch(function (error) {
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
            dispatch(setSnackBarVariant('error'))
            setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
        })
    }

    function onChangeImageHandler(event) {
        const imageFile = event.target.files[0];
        const imageFilname = event.target.files[0].name;
        if (!imageFile) {
            setinvalidImage('Please select image.');
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
            setinvalidImage('Please select valid image JPG,JPEG,PNG');
            return false;
        }
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {

                //------------- Resize img code ----------------------------------
                var canvas = document.createElement('canvas');
                var width = img.width;
                var height = img.height;
                let WIDTH = 100
                canvas.width = WIDTH
                let ratio = WIDTH / width
                canvas.height = height * ratio;
                canvas.style.backgroundColor = 'transparent'
                var ctx = canvas.getContext("2d");
                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                ctx.canvas.toBlob((blob) => {
                    const file = new File([blob], imageFilname, {
                        type: 'image/jpeg',
                        lastModified: Date.now()
                    });
                    setThumbnailInfo({
                        ...thumbnailInfo,
                        file: file,
                        filepreview: URL.createObjectURL(imageFile),
                    })
                    image = file
                    if (id == undefined) {
                        createApi()
                    } else {
                        updateLink()
                    }
                }, 'image/jpeg', 1);
                setinvalidImage(null)
            };
            img.onerror = () => {
                setinvalidImage('Invalid image content.');
                return false;
            };
            //debugger
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
    }

    const onChangeRemoveImageHandler = (id) => {
        let updateData = new FormData();
        if (image) {
            updateData.append('image', image);
        }
        if (id) {
            updateData.append('id', id);
        }
        if (id) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarMessage(formSnackbar.PRE_CREATER_LINK_UPDATE))
            postRemoveImage(updateData, userData.id).then(function (response) {
                dispatch(setSnackBarMessage(formSnackbar.POST_CREATER_LINK_UPDATE))
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
            })
                .catch(function (error) {
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                })
        }
    }
    const onChangeStatus = (e) => {
        if (e.target.checked) {
            status = 1
            setStatusVal(1)
        } else {
            status = 0
            setStatusVal(0)

        }
        updateLink();
    }

    return (
        <>
            <div ref={id !== undefined ? provided.innerRef : null} {...(id !== undefined ? provided.draggableProps : null)} {...(id !== undefined ? provided.dragHandleProps : null)}>
                <div className="col-md-12 my-3 col-sm-12 col-12 rounded-3 border shadow bg-white" id={`card_${index}`}>
                    <div id={`card_${id}`}>
                        {/* Link desinging start */}
                        <div className="link-box">
                            <div className="drag-hanlder">
                                <div className="my-auto text-secondary cursorGrab text-center">
                                    <DragIndicator />
                                </div>
                            </div>
                            <div className="link-details">
                                {/*                                 <form> */}
                                <div className="link-inputs"> {/*  link-input start */}
                                    {/*  title  */}
                                    <div className="profile-wrapper flex-wrap">
                                        {/* <BootstrapTooltip sx={{marginTop:'-10px !important'}}title={invalidImage} open={invalidImage?true:false} arrow> 
                                         */}    <div className="profile-pic-wrapper">
                                            <div className="pic-holder">
                                                <img id={data.id} src={data.image == null || data.image === "" || !data.image ? userThumbnai : process.env.REACT_APP_BASEURL_MEDIA + '/media/' + data.image} className="pic rounded-circle  imageOpacity d-block" onError={(e) => { e.target.src = userThumbnai }} alt="img" style={{ width: '71px', height: '71px' }} />
                                                <input className="uploadProfileInput position-absolute" type="file" name="profile_pic" id={`linkIcon_${data.id}`} accept="image/*" style={{ opacity: '0' }} onChange={onChangeImageHandler} />
                                                <label htmlFor={`linkIcon_${data.id}`} className="upload-file-block">
                                                    <div className="text-center" title="Please upload an image with the resolution of max 250*250px">
                                                        <div className="mb-2">
                                                            <i className="fa fa-camera fa-2x"></i>
                                                        </div>
                                                        <div className="text-uppercase">
                                                            Update <br />
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {invalidImage && <div className={`custom-tooltip`}>
                                            <span className="tooltip-text">{invalidImage}</span>
                                            <span className="top"></span>
                                        </div>}
                                        {/*  </BootstrapTooltip> */}
                                    </div>
                                    <div className="link-wrapper">
                                        {/* Right side btns */}
                                        <div className="link-input-btns">
                                            <div className="form-check form-switch">
                                                <label className="switch" title="Link visibility">
                                                    <input disabled={disable} checked={statusVal === 1 ? true : false} type="checkbox" role="switch" id={data.id} onChange={(e) => onChangeStatus(e)} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="link-input-fields">
                                            <div className="w-100">
                                                <EditText name="title"
                                                    id={`linkTitle_${data.id}`}
                                                    className="w-100"
                                                    defaultValue={text} onSave={handleTitleSave} placeholder="Title" />
                                                {errorTitleText && <div className={`custom-tooltip`}>
                                                    <span className="tooltip-text">{errorTitleText}</span>
                                                    <span className="top"></span>
                                                </div>}
                                            </div>
                                        </div>
                                        {/* Link url */}

                                        <div className="link-input-fields">
                                            <div className="w-100">
                                                <EditText name="url"
                                                    id={`linkUrl_${data.id}`}
                                                    className={`w-100`}
                                                    defaultValue={url} onSave={handleSave} placeholder="Url" />
                                                {/*  <span class="text-danger">{errorText}</span> */}
                                                {errorText && <div className={`custom-tooltip`}>
                                                    <span className="tooltip-text">{errorText}</span>
                                                    <span className="top"></span>
                                                </div>}
                                            </div>
                                        </div>

                                        {/* action button container:start */}
                                        <div className="action-btn-container">
                                            <div className="inner-container" style={{ display: 'flex' }}>
                                                <div className='link-action-btns'>
                                                    {/*  <div className="m-1">
                                                                    <a href="#" onClick={(e) => handleLinkModule(e,'add-thumbnail',data.id)} id={`linkThumbnail_${data.id}`} data-id={data.id} title="Add Thumbnail"> <img src={addThumbnail} id={`thumbnail_${data.id}`} style={{ height: "30px", width: "30px" }}  alt="img"/></a>
                                                                </div>
                                                            */}
                                                </div>
                                                <div>
                                                    <div>
                                                        {/* <button className='gn-btn-icononly' href="#"> <span id={data.id} onClick={() => deleteHandler(id, index)}> <DeleteIcon /></span></button> */}
                                                        {/* <button title="Delete" className='gn-btn-icononly' href="#"> {data && data.id!==undefined?<span id={data.id} onClick={()=>ref.current.handleOpen()}> <DeleteIcon /></span>:<span id={data.id} onClick={() => deleteHandler(id, index)}> <DeleteIcon /></span>}</button> */}
                                                        <button title="Delete" className='gn-btn-icononly text-secondary' href="#">
                                                            {data && data.id !== undefined ? <span id={data.id} onClick={() => ref.current.handleOpen()}> <DeleteIcon /></span> : <span id={data.id} onClick={() => deleteHandler(id, index)}> <DeleteIcon /></span>}
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>{/* action button container:end */}
                                    </div> {/* Link wrapper ends here */}

                                </div>{/*  link input close */}
                                {/*   </form> */}
                                {/* Belwo action buttons */}

                            </div>
                        </div>
                        {/* section for delete confirm modal */}
                        <DeleteModal cardId={data && data.id} deleteHandler={deleteHandler} ref={ref} />
                    </div>
                </div>
                <div
                    ref={bodyRef}
                    tabIndex={-1}
                    style={{ position: 'absolute', left: '-9999px' }}
                ></div>
            </div>
        </>
    )
};
