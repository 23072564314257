import { DragIndicator, MoreVert } from '@mui/icons-material';
import React, { useState } from 'react';
import artist from '../../img/Frame 48096170.png';
import { Button, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import spotify from '../../img/streaming-platform-images/spotify.svg';
import JioSaavn from '../../img/streaming-platform-images/jio-saavn.svg';
import Wynk from '../../img/streaming-platform-images/wynk.svg';
import AmzazonMusic from '../../img/streaming-platform-images/amazon-music.svg';
import Gaana from '../../img/streaming-platform-images/gaana.svg';
import AppleMusic from '../../img/streaming-platform-images/apple-music.svg';
import Hungama from '../../img/streaming-platform-images/hungama.svg';
import YtMusic from '../../img/streaming-platform-images/yt-music.svg';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import { useHistory } from 'react-router-dom';

const AlbumSongItem = ({ songData, editSongFromPlayList, removeSongFromPlayList, provided, albumId, showReadyForRelease, albumData, albumValidation }) => {
  const history = useHistory()
  const mdScreen = useMediaQuery('(max-width:768px)');
  const xmScreen = useMediaQuery('(max-width:300px)');
  const smScreen= useMediaQuery('(max-width:600px)');
  const [isDragging, setIsDragging] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveClick = () => {
    removeSongFromPlayList(songData.song.song_id);
    setAnchorEl(null); // Close the menu after removing
  };

  const frameWidth = 8; // Set your desired frame width
  const frameHeight = 4; // Set your desired frame height

  const frameStyle = {
    width:xmScreen?'5rem' :'8rem',
    height:xmScreen?'3rem': '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: 'black',
    marginRight: '10px',
  };
  const iconStyle = {
    position: 'absolute', // Position the MoreVert icon absolutely
    top: '5px', // Adjust the top and right values to position it as needed
    right: '2px',
  };
  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };
  const iconButtonStyle = {
    background: 'transparent', // Set a transparent background to remove the gray color

  };


  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const openDetail = (songId)=>{
    history.push("/releases/" + songId + "/" + albumId)

  }

  const isMenuOpen = Boolean(anchorEl);

  return (
    <div ref={provided.innerRef} {...provided.draggableProps}>
      <div
        className={`list-container ${isDragging ? 'dragging' : ''}`}
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      // style={{ paddingLeft: '6px' ,paddingRight:'6px'}}
      >
        <div className="col-md-12 my-3 col-sm-12 col-12 rounded-3 border shadow-sm bg-white">
          <div className="link-box">
            <div className="drag-hanlder" {...provided.dragHandleProps} style={{minWidth:smScreen?'24px':'36px'}}>
              <div className="my-auto text-secondary cursorGrab text-center">
                <DragIndicator />
              </div>
            </div>
            <div className='w-100 p-2 ' >
              <div className="link-inputs">
                <div style={{ width: '100%' }}>
                  <div className='d-flex'>
                    <div className='rounded-1 cursorPointer' style={frameStyle} onClick={()=>openDetail(songData.song.song_id,albumId)}>
                      <img
                        className='rounded-1'
                        src={songData.song && songData.song.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + songData.song.cover_image : artist}
                        alt="img"
                        style={imageStyle}
                      />
                    </div>
                    <div style={{ paddingLeft: mdScreen ? '' : '0.5rem', width: '100%' }}>
                      <div className='d-flex justify-content-between'>
                        <div className='w-100'>
                          <p className='titlea' id='video-title' title={songData.song.song_name} style={{width:smScreen?'80%':'90%',fontSize:smScreen?'0.7rem':''}}>
                            {songData.song.song_name}
                          </p>
                        </div>
                        <div >
                          {!mdScreen ? (
                            <div className='d-flex'>
                              {/* {console.debug("wchvjwcvhjvchj:album",(albumData.song_status))}
                              {console.debug("wchvjwcvhjvchj:song",(songData.song.song_status))}

                              {console.debug("wchvjwcvhjvchj:song",(songData.song.song_status == "Released" ))}

                              {console.debug("wchvjwcvhjvchj:albumValidation",(albumData.song_status =="In Review"  || albumData.song_status =="Released" || albumData.song_status =="Ready for Release")&& (songData.song.song_status == "Ready for Release" || songData.song.song_status == "In Review"|| songData.song.song_status == "Released") ? true : false)} */}

                            <Button variant="text" color='error' onClick={() => editSongFromPlayList(songData.song.song_id, albumId)} disabled={((albumData.song_status =="In Review"  || albumData.song_status =="Released" || albumData.song_status =="Ready for Release")&& (songData.song.song_status == "Ready for Release" || songData.song.song_status == "In Review"|| songData.song.song_status == "Released") ) ? true : false}>
                              <strong style={{ fontSize: mdScreen ? '10px' : '12px' }}>EDIT</strong>
                            </Button>
                            <Button variant="text" color='error' onClick={() => removeSongFromPlayList(songData.song.song_id)} disabled={(albumData.song_status =="In Review"|| albumData.song_status =="Released"|| albumData.song_status =="Released" || albumData.song_status == "Ready for Release") && (songData.song.song_status =="In Review" || songData.song.song_status == "Ready for Release" || songData.song.song_status == "Released") ? true : false}>
                                 <strong style={{ fontSize: mdScreen ? '10px' : '12px' }}>REMOVE</strong>
                               </Button>
                               </div>
                          ) : (
                            <div style={{marginLeft:'10px'}}>
                              <IconButton aria-label="more" aria-controls="song-menu"  onClick={handleClick}  style={{ ...iconStyle, ...iconButtonStyle }}>
                                <MoreVert />
                              </IconButton>
                            </div>
                          )}
                          <Menu
                            id="song-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={isMenuOpen}
                            onClose={handleClose}
                            sx={{ marginLeft: '-20px' }}
                          >
                            <MenuItem onClick={handleRemoveClick}>Remove</MenuItem>
                          </Menu>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between flex-wrap mt-1' >
                        <div className='fs-08 w-75'>Artist: {songData?.song ? songData?.song?.primary_artists.map(artist => (<span className='text-capitalize'>{" "+artist.artist_name}</span>)):''}</div>
                        <div className='fs-08 status text-light' style={{ backgroundColor: getSongStatusBgColor(songData.song.song_status == 'In Review'? 'Completed':songData.song.song_status) }}>{songData.song.song_status == 'In Review'? 'In Review' : songData.song.song_status == 'draft'?"Draft":songData.song.song_status}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlbumSongItem;
