import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Category from './category';
import Genre from './genre';
import Phone from './phone';
import General from './general';
import Expertise from './expertise';
import Experience from "./experience"
import Social from './social';
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { fetchUserDetail } from '../../redux/user/userDataActions';
import SnackbarContainer from '../Snackbar';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import SocialPlatform from './SocialPlatform';
import Region from './region';
import Metapixel from './metapixel';
import Setting from './setting';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function Profile(props) {
    const [type, setType] = useState("category")
    const [active, setActive] = useState("6")
    let { childPath } = useParams();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const [profileTabs, setProfileTabs] = useState([
        {
            id: 6,
            tabActiveId: "6",
            tabTitle: 'General',
            tabType: 'general'
        },
        {
            id: 1,
            tabActiveId: "1",
            tabTitle: 'Category',
            tabType: 'category'
        },
        {
            id: 2,
            tabActiveId: "2",
            tabTitle: 'Genre',
            tabType: 'genre'
        },
        {
            id: 3,
            tabActiveId: "3",
            tabTitle: 'Social Platform',
            tabType: 'socialPlatform'
        },
        {
            id: 4,
            tabActiveId: "4",
            tabTitle: 'Experience',
            tabType: 'experience'
        },
        {
            id: 5,
            tabActiveId: "5",
            tabTitle: 'Expertise',
            tabType: 'expertise'
        },
        {
            id: 8,
            tabActiveId: "8",
            tabTitle: 'Region',
            tabType: 'region'
        },
        {
            id: 9,
            tabActiveId: "9",
            tabTitle: 'Meta Pixel',
            tabType: 'metapixel'
        },
        // {
        //     id:10,
        //     tabActiveId: "10",
        //     tabTitle: 'Setting',
        //     tabType: 'setting'
        // }

    ])
    useEffect(function () {
        dispatch(fetchUserDetail());
        let currentURL = window.location.href.split('/profile?user=')[1]
        if (currentURL === undefined)
            currentURL = window.location.href.split('/?user=')[1]
        const currentTab = profileTabs.find(item => item.tabType === currentURL)
        currentTab && setActive(currentTab.tabActiveId)
        if (userData.id) {
            dispatch(fetchLoginUser(userData.id));
        }

    }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const mediumViewport = useMediaQuery('(min-width:1100px)');
    const tabStyle = {
        bgcolor: 'background.paper',
        border: 'solid 1px #868686',
        borderRadius: '10px',
        margin: mediumViewport ? '20px' : '10px 0px 10px 0px',
        // padding:'20px'
    }
    return (
        <>
            <div className='p-4'>
                <div className="d-flex main-profile-container">
                    <div className="profile-tabs">
                        <Box sx={tabStyle}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                orientation={mediumViewport ? "vertical" : "horizontal"}
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                                indicatorColor=""
                            >
                                {profileTabs ? profileTabs.map(item => {
                                    return (
                                        <Link key={item.id} to={`?user=${item.tabType}`} className={active === item.tabActiveId ? "text-danger fw-bold text-decoration-none" : "text-dark fw-bold text-decoration-none"} onClick={() => { setType(item.tabType); setActive(item.tabActiveId) }}>
                                            <Tab label={item.tabTitle} sx={{ textAlign: 'left' }} />
                                        </Link>
                                    )
                                }) : null}
                            </Tabs>
                        </Box>
                    </div>
                    <div className="probile-tab-content">
                        <Box sx={{ /* maxWidth: { xs: 320, sm: 200 }, */ bgcolor: 'background.paper', border: 'solid 1px #868686', borderRadius: '10px', margin: '20px 0px', marginBottom: '40px', padding: '20px' }}>
                            {(() => {
                                switch (window.location.search) {
                                    case '?user=category':
                                        return <Category />;
                                    case '?user=phone':
                                        return <Phone />;
                                    case '?user=general':
                                        return <General />;
                                    case '?user=expertise':
                                        return <Expertise />;
                                    case '?user=experience':
                                        return <Experience />;
                                    case '?user=socialPlatform':
                                        return <Social />;
                                    case '?user=genre':
                                        return <Genre />;
                                    case '?user=region':
                                        return <Region />;
                                    case '?user=metapixel':
                                        return <Metapixel />;
                                    // case '?user=setting':
                                    //     return <Setting />;
                                    default:
                                        return <General />
                                }
                            })()}
                        </Box>
                    </div>
                </div>
            </div>
            <SnackbarContainer />
        </>
    );
}

export default Profile;