import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import defaultImage from '../img/default image.png';
import { useMediaQuery } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';

const MAX_CHAR_LIMIT = 50; // Adjust the character limit as needed

function truncateText(text, limit) {
  return text.length > limit ? text.substring(0, limit) + '...' : text;
}

export default function EnquiryList({ selected, index, cardData, moduleName, handleClick }) {
  const smScreen = useMediaQuery('(max-width:340px)'); // Adjust the max-width value
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isItemSelected = isSelected(cardData.id);
  const labelId = `enhanced-table-checkbox-${index}`;

  const showInListView = (module) => {
    switch (module) {
      case 'enquiryList': {
        const truncatedMessage = truncateText(cardData.message, MAX_CHAR_LIMIT);
        const truncatedEmail = smScreen
          ? truncateText(cardData.email, 14) // Truncate email to 20 characters if smScreen is true
          : truncateText(cardData.email, MAX_CHAR_LIMIT); // Truncate email to MAX_CHAR_LIMIT if smScreen is false

        return (
          <div className='my-3 responsive-list' style={{ cursor: 'pointer' }}>
            <Card sx={{ maxWidth: '500px', width: '100%' }}>
              <div className='d-flex w-100'>
                <div >
                  <Checkbox
                    onChange={(event) => handleClick(event, cardData.id)}
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </div>
                <div style={{ padding: '0.5rem', flexGrow: 1 }}>
                  <div title={`${cardData.first_name} ${cardData.last_name}`}>
                    <strong className='cardheadingFont overflow-text'>Name : </strong>
                    {cardData.first_name} {cardData.last_name}
                  </div>
                  <div title={cardData.email}>
                    <strong className='cardheadingFont overflow-text'>Email : </strong>
                    {''}
                    {truncatedEmail}
                  </div>
                  <div>
                    <strong className='cardheadingFont overflow-text'>Mobile No. : </strong>
                    {cardData.mobile_number}
                  </div>
                  <div title={cardData.message}>
                    <strong>Message:</strong> {truncatedMessage}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return showInListView(moduleName);
}
