import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import profileImge from '../../img/profileImage.png'
import { updateProfile } from '../../_services/profile/profileUpdate.service';
import { userHandler } from '../../_services/profile/userHandle.service'
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { setLoader } from '../../redux';
import { fetchUserDetail } from '../../redux/user/userDataActions';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { getLanguage } from '../../_services/profile'
import '../../admin/style.css'
import { FormControl, InputLabel, MenuItem, Select, selectClasses } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import AvatarEditor from 'react-avatar-editor';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import CropIcon from '@mui/icons-material/Crop';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';


function General(props) {
    const smScreen = useMediaQuery('(max-width:600px)');
    const xsSmScreen = useMediaQuery('(max-width:375px)');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: smScreen ? '100%' : 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 3,
    };
    const userData = useSelector((state) => state.userData.userData);
    const userDetail = useSelector((state) => state.userDetail.userDetail);
    const dispatch = useDispatch();
    const [saveBtn, setSaveBtn] = useState(true)

    const [userHandle, setUserHandle] = useState("")
    const [errorUserHandle, setErrorUserHandle] = useState(false)
    const [handleErrorState, setHandleErrorState] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const [userName, setUserName] = useState("")
    const [errorName, setErrorName] = useState(false);
    const [helperTextName, setHelperTextName] = useState("")

    const [contactNumber, setContactNumber] = useState("")
    const [contactNumberError, setContactNumberError] = useState(false)

    const [languageList, setLanguageList] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [languageError, setLanguageError] = useState(false)

    const [about, setAbout] = useState("")
    const [userThumbnai, setUserThumbnai] = useState("");
    const [avatarFile, setAvatarFile] = useState()

    const [stageName, setStageName] = useState("")
    const [errorStageName, setErrorStageName] = useState(false);
    const [helperTextStageName, setHelperTextStageName] = useState("")

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const editorRef = React.useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [scale, setScale] = useState(1);
    const mobileNumberRegex = /^[0-9]{10}$/;
    const allowedExtensions = ['.png', '.jpg', '.jpeg'];

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        const fileExtension = '.' + file.name.split('.').pop();
        // Validation: Allowed file formats
        if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage('Invalid file format. Only PNG/JPG/JPEG files are allowed.'))
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            return;
        }

        // Validation: Maximum file size
        const maxSize = 10 * 1024 * 1024; // 10MB
        if (file.size > maxSize) {
            // Show error message or handle oversized file
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage('File size exceeds the maximum limit (10MB).'))
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            return;
        }

        // Validation: Allowed resolution
        const allowedResolution = { width: 500, height: 500 };
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            // All validations passed, proceed with handling the file
            handleOpen(); // Open the modal
            setSelectedFile(file);
            setPreviewImage(URL.createObjectURL(file));
            // Reset the input element's value to allow selecting the same file again
            e.target.value = null;
        };
    };

    const handleCrop = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas();
            const croppedImageUrl = canvas.toDataURL();
            setCroppedImage(croppedImageUrl);
            setUserThumbnai(croppedImageUrl)
            canvas.toBlob((blob) => {
                setAvatarFile(blob)
            }, 'image/jpeg');
        }
        handleClose() //close the modal
    };


    const handleScaleChange = (e) => {
        const scaleValue = parseFloat(e.target.value);
        setScale(scaleValue);
    };

    /* for setting flag and code with dropdown */
    useEffect(function () {
        if (userData.id) {
            dispatch(fetchLoginUser(userData.id));
            setUserHandle(userData ? userData.userHandle : "")
            setAbout(userData ? userData.about : "")
            setContactNumber(userData ? userData.contactNumber : "")
            setUserName(userData ? userData.name : "")
            setStageName(userData?.stage_name || userData?.name || '')
            userData.languages ? setSelectedLanguages(userData.languages.map(item => item)) : setSelectedLanguages(null)
        }
        getLanguage().then(function (response) {
            setLanguageList(response.data ? response.data.data : "")
            dispatch(setLoader(false))
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })

    }, [userData.id]);
    //to make the blob as valid image format
    function getOriginalImageName(blobFile) {
        const file = new File([blobFile], 'temp.jpg', { type: blobFile.type });
        return file.name;
    }
    const saveAvatar = async function () {
        let postData = new FormData()
        postData.append("avatar", avatarFile, getOriginalImageName(avatarFile))
        /* postData.append("userId", userData.id) */
        if (avatarFile) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('info'))
            dispatch(setSnackBarMessage(formSnackbar.PRE_PROFILE_UPDATE))
            //To handle the image change with the update profile//Two services called dependent of each other
            try {
                const response = await updateProfile(postData, userData.id)
                dispatch(setSnackBarMessage(formSnackbar.POST_PROFILE_UPDATE))
                dispatch(setSnackBarVariant('success'))
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
                dispatch(fetchUserDetail(userData))
                dispatch(fetchLoginUser(userData.id)); //to reflect the profile image changes on header
                dispatch(setLoader(false))
                setAvatarFile(undefined)
            }
            catch (err) {
                dispatch(setLoader(false))
                dispatch(setSnackBarMessage(formSnackbar.errorMsg))
                dispatch(setSnackBarVariant('error'))
                setTimeout(() => dispatch(setSnackBar(false)), 2000);
                setAvatarFile(undefined)
            }
        }
    }


    // Function to convert data URL to File object
    function dataURLtoFile(dataURL, filename) {
        var arr = dataURL.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const validateForm = () => {
        if (userHandle.trim() === "" || userHandle.trim() == null) {
            setErrorUserHandle(true)
            return false;
        }
        else if (userName === '' || userName === null) {
            setErrorName(true)
            return false
        }
        else if (contactNumber === "" || contactNumber === null || contactNumber <= 0) {
            setContactNumberError(true)
            return false;
        }
        else if (selectedLanguages === "" || selectedLanguages === null || selectedLanguages.length <= 0) {
            setLanguageError(true)
            return false
        }
        else
            return true
    }

    const phoneChangeHandler = (e) => {
        const value = e.target.value;
        if (mobileNumberRegex.test(value)) {
            setContactNumber(value);
            setContactNumberError(false)
        } else {
            setContactNumber(value);
            setContactNumberError(true)
        }
    }

    function updateUserProfile(e, id, postData) {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            /* var data = new FormData(); */
            let dataObj = {}
            if (userHandle && userHandle !== userData.userHandle) {
                //data.append("userHandle", userHandle)
                dataObj.userHandle = userHandle
            }
            if (userName && userName !== userData.name) {
                //data.append("name", userName)
                dataObj.name = userName
            }
            if (stageName && stageName !== userData.stage_name) {
                //data.append("name", userName)
                dataObj.stage_name = stageName == "" ? userName : stageName
            }
            if (contactNumber && contactNumber !== userData.contactNumber) {
                //data.append("contactNumber", contactNumber)
                dataObj.contactNumber = contactNumber
            }
            if (selectedLanguages) {
                const currentLanguages = userData.languages.map(language => language.id)
                let languageIds = selectedLanguages.map(value => value.id)
                console.log(currentLanguages, languageIds)
                if (JSON.stringify(currentLanguages) !== JSON.stringify(languageIds))
                    dataObj.languages = languageIds.join(',')
                //data.append("languages", languageIds.join(','))

            }
            if (about && about !== userData.about) {
                //data.append("about", about)
                dataObj.about = about
            }
            /* const formData = new FormData();
            for (const key in dataObj) {
                formData.append(key, dataObj[key]);
            } */

            if (Object.keys(dataObj).length === 0 && avatarFile === undefined) {
                dispatch(setSnackBar(true))
                dispatch(setSnackBarVariant('warning'))
                dispatch(setSnackBarMessage(formSnackbar.errors.noChangeFound))
                setTimeout(() => dispatch(setSnackBar(false)), 1500);
            }
            else {
                dispatch(setLoader(true))
                dispatch(setSnackBar(true))
                dispatch(setSnackBarVariant('info'))
                dispatch(setSnackBarMessage(formSnackbar.PRE_USERHANDLE_UPDATE))
                updateProfile(dataObj, userData.id).then(function (response) {
                    if (response.status === 200) {
                        setErrorUserHandle("")
                        if (avatarFile != undefined)
                            saveAvatar();
                        else {
                            dispatch(setSnackBarMessage(formSnackbar.POST_PROFILE_UPDATE))
                            dispatch(setSnackBarVariant('success'))
                            setTimeout(() => dispatch(setSnackBar(false)), 3000);
                            dispatch(fetchUserDetail(userData))
                            dispatch(setLoader(false))
                        }
                    }

                    else {
                        dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                        dispatch(setSnackBarVariant('error'))
                        setTimeout(() => dispatch(setSnackBar(false)), 3000);
                        dispatch(setLoader(false))
                    }
                })
            }
        }
    }

    function userHandlerChangeHandler(e) {
        setLoading(true)
        // let inputlength = e.target.value.trim().length;
        const inputValue = e.target.value.toLowerCase().replace(/\s+/g, '');
        let inputlength = inputValue.length;
        // let inputlength = e.target.value.trim().length;

        if (inputlength < 50 && inputlength > 0) {
            // setUserHandle(e.target.value.trim())
            // searchUserHandle(e.target.value.trim())
            setUserHandle(inputValue)
            searchUserHandle(inputValue)
            setErrorUserHandle(null)
            setHandleErrorState(false)
        }
        else if (inputlength === 0) {
            // setUserHandle(e.target.value.trim())
            // searchUserHandle(e.target.value.trim())
            setUserHandle(inputValue)
            searchUserHandle(inputValue)
            // called to handle the response if input lenght is zero
            setHandleErrorState(true)
            setErrorMsg(null)
            setSaveBtn(false)
        }
        else {
            setErrorUserHandle('User handle can not be more than 50 characters')
            setHandleErrorState(true)
            setErrorMsg(null)
            setSaveBtn(false)
        }

    }
    function userNameChangeHandler(e) {
        setUserName(e.target.value)
        let nameLength = e.target.value.length;
        if (nameLength >= 3 && nameLength < 50) {
            setErrorName(false)
            setHelperTextName(null)
            setSaveBtn(true)
        }
        else {
            setErrorName(true)
            setHelperTextName('Name must contain atleast 3 characters and max up to 50 characters.')
            setSaveBtn(false)
        }
    }

    function stageNameChangeHandler(e) {
        setStageName(e.target.value)
        let nameLength = e.target.value.length;
        if (nameLength >= 3 && nameLength < 50) {
            setErrorStageName(false)
            setHelperTextStageName(null)
            setSaveBtn(true)
        }
        else {
            setErrorStageName(true)
            setHelperTextStageName('Name must contain at least 3 characters and max up to 50 characters.')
            setSaveBtn(false)
        }
    }

    function onAboutChangeHandler(e) {
        setAbout(e.target.value)
    }

    function searchUserHandle(userName) {
        var postData = {}
        console.debug(userHandle, "e")
        postData['userHandle'] = userName
        userHandler(postData).then(function (response) {
            if (response.data.available === 'true') {

                if (userName && userName.length !== 0) {
                    if (userName && userName.length > 4) {
                        setErrorUserHandle('This user handle is available')
                        setSaveBtn(true)
                    }
                    else {
                        setErrorUserHandle('Enter atleast 5 character')
                        setSaveBtn(false)
                    }
                } else {
                    setErrorUserHandle('User handle can not be empty')
                    setSaveBtn(false)
                }
            }
            else {
                if (userData) {
                    if (userData.userHandle === userName) {
                        setSaveBtn(true)
                        setHandleErrorState(false)
                        setErrorUserHandle("")
                        //setErrorMsg(null)
                    }
                    else {
                        //setErrorMsg("User handle is not available")
                        setHandleErrorState(true)
                        setErrorUserHandle("User handle is not available")
                        setSaveBtn(false)
                    }
                }

            }
            setTimeout(() => setLoading(false), 1000)

        }).catch(function (error) {
            console.log(error.response);
            return error.response;
        })
    }
    const handleLanguageChange = (event, value) => {
        setSelectedLanguages(value)
        setLanguageError(false)
    }
    const filteredLanguage = languageList.filter(
        (option) => !selectedLanguages.includes(option.language)
    )
    // Function to determine if an option is disabled (already selected)
    const getOptionDisabled = (option) => {
        return selectedLanguages.some((selectedOption) => selectedOption.id === option.id);
    };

    return (
        <div>
            <h4 className='mb-2'>General</h4>
            <div className='text-secondary'>
                <p className="mb-2">Please upload some basic information about yourself</p>
            </div>
            {/* Image:start */}
            <div className='d-flex'>
                <div className="avatar-upload" title="Upload your image (Resolution: 250px*250px)">
                    <div className="avatar-edit">
                        <input type='file' onChange={handleFileSelect} id="imageUpload" accept=".png, .jpg, .jpeg" />
                        <label htmlFor="imageUpload">
                            <PhotoCameraIcon />
                        </label>
                    </div>
                    <div className="avatar-preview">
                        <div id="imagePreview" /* style="background-image: url('http://i.pravatar.cc/500?img=7');" */>
                            <img src={userThumbnai ? userThumbnai : userData == "" ? profileImge : process.env.REACT_APP_BASEURL_MEDIA + '/media/' + userDetail.avatar} className="h-75 rounded-circle w-75" onError={(e) => { e.target.src = profileImge }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-secondary'>
                <span style={{ fontSize: '0.8rem' }}>Allowed file formats are PNG and JPEG, and the maximum file size is 10MB.</span>
            </div>

            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        onClick: null, // Disable close on background click
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <div className='d-flex'>
                                <div>
                                    <Typography id="transition-modal-title" variant="h6" component="h2">Crop and Save Image</Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        Adjust the crop area to select the desired portion of the image. Click the 'Save' button to save the cropped image.
                                    </Typography>
                                </div>
                                <div>
                                    <IconButton onClick={handleClose} aria-label="Close" color="secondary">
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>

                            {previewImage && (
                                <div>
                                    <div className="m-2" style={{ width: '100%', maxWidth: '100%', backgroundColor: 'transparent', border: 'solid 2px', borderRadius: '8px', borderStyle: 'dashed', textAlign: 'center' }}>
                                        <AvatarEditor
                                            ref={editorRef}
                                            image={previewImage}
                                            width={xsSmScreen ? 190 : 230}
                                            height={xsSmScreen ? 190 : 230}
                                            border={50}
                                            color={[255, 255, 255, 0.6]}
                                            scale={scale}
                                            borderRadius={115}
                                            rotate={0}
                                            style={{ touchAction: "manipulation" }}
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex align-items-center'>
                                            <div style={{ width: '100px' }}>
                                                <Typography variant="body1"><ZoomInIcon /> Zoom In</Typography>
                                            </div>
                                            <div style={{ width: '10px' }}></div>
                                            <div style={{ flexGrow: '1', maxWidth: '12rem', marginTop: '5px' }}>
                                                <Slider
                                                    min={1}
                                                    max={2}
                                                    step={0.1}
                                                    value={scale}
                                                    onChange={handleScaleChange}
                                                />
                                            </div>
                                        </div>
                                        <button className='gn-actionbtn' onClick={handleCrop} ><CropIcon /> Crop</button>
                                    </div>
                                </div>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>

            {/* Image:end */}
            <div className='mt-4'>
                <div className='d-flex p-0 flex-wrap'>
                    <TextField
                        label="User Name *"
                        id="outlined-size-small"
                        placeholder='Search Username'
                        onChange={userHandlerChangeHandler}
                        // onChange={(e) => {e.preventDefault();setUserHandle( e.target.value)?setErrorUserHandle(true):setErrorUserHandle(false);} }
                        helperText={errorUserHandle}
                        error={handleErrorState}
                        value={userHandle}
                        size="small"
                        sx={{ width: '100%' }}
                        name="userHandle"
                        color="secondary"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Fade
                                        in={loading}
                                        style={{
                                            transitionDelay: loading ? '800ms' : '0ms',
                                        }}
                                        unmountOnExit
                                    >
                                        <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} />
                                    </Fade>
                                    {handleErrorState ? (
                                        <ErrorOutline color="error" />
                                    ) : (
                                        errorUserHandle != "" ? <CheckCircleOutline color="success" /> : ""
                                    )}
                                </InputAdornment>
                            )
                        }}
                        FormHelperTextProps={{
                            style: {
                                color: handleErrorState ? 'red' : 'green', // Set color based on error state
                            }
                        }}
                    />
                </div>
            </div>
            <div className='mt-4'>
                <TextField
                    label="Full Name *"
                    id="outlined-size-small"
                    placeholder='Please enter your name'
                    onChange={userNameChangeHandler}
                    value={userName}
                    size="small"
                    sx={{ width: '100%' }}
                    name="userName"
                    color="secondary"
                    type="text"
                    error={errorName}
                    helperText={helperTextName}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                />
            </div>
            <div className='mt-4'>
                <TextField
                    label="Stage Name *"
                    id="outlined-size-small"
                    placeholder='Please enter your stage name'
                    onChange={stageNameChangeHandler}
                    value={stageName}
                    size="small"
                    sx={{ width: '100%' }}
                    name="userName"
                    color="secondary"
                    type="text"
                    error={errorStageName}
                    helperText={helperTextStageName}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                />
            </div>
            <div className='mt-4'>
                <TextField
                    label="Phone No"
                    id="outlined-size-small"
                    placeholder='Phone No'
                    onChange={phoneChangeHandler}
                    value={contactNumber}
                    size="small"
                    sx={{ width: '100%' }}
                    name="contactNumber"
                    color="secondary"
                    type="number"
                    error={contactNumberError}
                    helperText={contactNumberError ? 'Please add a correct contact number' : ''}
                    InputProps={{
                        // inputProps: { maxLength: 10 },
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                    required
                />
                {/* <PhoneInput
                    country={'in'}
                    value={contactNumber}
                    name="contactNumber"
                    placeholder=""
                    onChange={phoneChangeHandler}
                    specialLabel="Phone No"
                />
                {contactNumberError && (
                    <Typography color="error" variant="caption" display="block" gutterBottom>Please add a correct contact number</Typography>
                )} */}
            </div>
            <div className='mt-4'>
                <FormControl fullWidth={true} size='small'>
                    <Autocomplete
                        multiple
                        id="language-field"
                        size="small"
                        limitTags={6}
                        options={filteredLanguage}
                        getOptionLabel={option => option.language}
                        onChange={handleLanguageChange}
                        value={selectedLanguages}
                        getOptionDisabled={getOptionDisabled}
                        renderInput={(params) => (
                            <TextField {...params} error={languageError} label="Language *" helperText={languageError ? 'Please select a language.' : ''} placeholder="Language" />
                        )}
                    />
                </FormControl>
            </div>
            <div className='mt-4'>
                <TextField
                    id="artist-description"
                    label="About"
                    multiline
                    rows={4}
                    value={about}
                    onChange={onAboutChangeHandler}
                    placeholder='Please write about yourself'
                    sx={{ width: '100%' }}
                    color="secondary"
                    name="about"

                />
            </div>
            <div className='mt-2 mb-5'>
                {<button title="Save" disabled={userHandle && userName && stageName && contactNumber?.length && !contactNumberError && selectedLanguages?.length > 0 && saveBtn ? false : true} className='gn-actionbtn float-end' onClick={updateUserProfile}>Save</button>}
            </div>
        </div>
    );
}

export default General;