import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Add, Close } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, ListItemIcon, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PublicIcon from '@mui/icons-material/Public';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { getAllPlaylistOfUser } from '../../../../_services/playlist/getAllPlaylistOfUser';
import { searchPlaylist } from '../../../../_services/playlist/searchPlaylist';
import { createPlaylist } from '../../../../_services/playlist/createPlaylist';
import { updatePlaylist } from '../../../../_services/playlist/updatePlaylist';
import { useDispatch } from 'react-redux';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../../redux';
import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';
import CircularProgress from '@mui/material/CircularProgress';
import { useRef } from 'react';
import { useEffect } from 'react';
import { getSinglePlaylist } from '../../../../_services/playlist/getSinglePlaylist';
import useMediaQuery from '@mui/material/useMediaQuery';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '4px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function CreatePlaylistModal({ closeCreateModal, userId, songId, openPlaylist, handleOpenPlaylist, songData }) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [showNameField, setShowNameField] = React.useState(false);
  const [playlistName, setPlaylistName] = React.useState('');
  const [playlistItems, setPlaylistItems] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState(null);
  const [loading, setLoading] = React.useState(false)
  const [selectedPlaylist, setSelectedPlaylist] = React.useState(null)
  const [disable, setDisable] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const searchInputRef = useRef(null);
  const sm = useMediaQuery('(max-width:600px)');
  const handleClosed = () => {
    setOpen(false);
    setShowNameField(false);
    setSelectedPlaylist(null)
    closeCreateModal()
    handleOpenPlaylist()
    setPlaylistName('')
    getPlaylistList()
    setDisable(false)
    setLoader(false)
  };

  const handleToggleNameField = () => {
    setShowNameField(!showNameField);
  };

  const handlePlaylistNameChange = (e) => {
    setPlaylistName(e.target.value);
  };

  const toggleItem = (playlistId) => {
    if (selectedPlaylist === playlistId) {
      setSelectedPlaylist(null); // Deselect if already selected
      setDisable(false)
    } else {
      setSelectedPlaylist(playlistId); // Select the new item
      setDisable(true)
    }
    setPlaylistName('')
    setShowNameField(false)
  };

  const searchHandler = (event) => {
    setSearchInput(event.target.value)
  }


  function getPlaylistList() {
    getAllPlaylistOfUser({ search: userId, limit: 5, offset: 0 })
      .then(function (response) {
        setPlaylistItems(response.data.results)
        setLoading(false)
      })
      .catch(function (error) {
        console.error(error)
      })
  }
  //get playlsit as per the search data
  function getPlaylistBySearch() {
    searchPlaylist({ search: searchInput, user: userId })
      .then(function (response) {
        setPlaylistItems(response.data.results)
        setLoading(false)
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  const playlistCreateHandler = () => {
    if (selectedPlaylist === null) {
      setLoader(true)
      const dataObj = { playlist_name: playlistName, playlist_song: songId, user: userId }
      createPlaylist(dataObj)
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            // getPlaylistList()
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.playlist.playlistCreate))
            setTimeout(function () {
              dispatch(setSnackBar(false))
            }, 2000)
            handleClosed()
          }
        })
        .catch(function (error) {
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('error'))
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
          setTimeout(function () {
            dispatch(setSnackBar(false))
          }, 2000)
        })
    }
    else {
      const dataObj = { playlist_song: songId }
      let param = {
        id: selectedPlaylist
      }
      getSinglePlaylist(param)
        .then(function (response) {
          if (response.data.data.length) {
            let songs = response.data.data[0].song;
            let songExists = false;
            for (let i = 0; i < songs.length; i++) {
              let element = songs[i];
              if (element.id == songId) {
                songExists = true;
                dispatch(setSnackBar(true));
                dispatch(setSnackBarVariant('warning'));
                dispatch(setSnackBarMessage(formSnackbar.playlist.playlistWarning));
                setTimeout(function () {
                  dispatch(setSnackBar(false));
                }, 3000);
                break;
              }
            }
            if (!songExists) {
              updatePlaylist(selectedPlaylist, dataObj)
                .then(function (response) {
                  if (response.status === 200 || response.status === 201) {
                    dispatch(setSnackBar(true))
                    dispatch(setSnackBarVariant('success'))
                    dispatch(setSnackBarMessage(formSnackbar.playlist.playlistUpdate))
                    setTimeout(function () {
                      dispatch(setSnackBar(false))
                    }, 2000)
                  }
                })
                .catch(function (error) {
                  dispatch(setSnackBar(true))
                  dispatch(setSnackBarVariant('error'))
                  dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
                  setTimeout(function () {
                    dispatch(setSnackBar(false))
                  }, 2000)
                })
            }
            // getPlaylistList()
            handleClosed()
          }
        })

    }
  }

  React.useEffect(function () {
    setLoading(true)
    const getData = setTimeout(function () {
      if (searchInput === null || searchInput.length == 0)
        getPlaylistList()
      else
        getPlaylistBySearch()
    }, 400)
    return () => clearTimeout(getData)
  }, [searchInput])

  return (
    <React.Fragment>
      <Modal
        open={openPlaylist}
        onClose={handleClosed}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: sm ? 300 : 450 }}>
          <div className='d-flex justify-content-between'>
            <div>
              <h5 id="child-modal-title">Save music to</h5>
            </div>
            <div onClick={handleClosed}>
              <Close style={{ cursor: 'pointer', marginLeft: '5px' }} />
            </div>
          </div>
          <TextField
            id="standard-search"
            placeholder='Search'
            fullWidth
            type="search"
            variant="standard"
            onChange={searchHandler}
            inputRef={searchInputRef}
            InputProps={{
              startAdornment: (
                <div style={{ marginRight: '8px' }}>
                  <SearchIcon color="action" />
                </div>
              ),
            }}
          />
          <div className='pt-3'>
            <h5>Recent</h5>
          </div>
          <div className='overflow-y-auto height30vh'>
            {!loading ? playlistItems && playlistItems.length > 0 ? (playlistItems.map((item, index) => (
              <div className='d-flex justify-content-between my-1' key={index}>
                <div>
                  <FormControlLabel
                    className='align-items-start wordBreak'
                    control={
                      <Checkbox
                        className='pt-0'
                        checked={selectedPlaylist === item.playlist_id}
                        onChange={() => toggleItem(item.playlist_id)}
                      />
                    }
                    label={item.playlist_name}
                  />
                </div>
                <div style={{ alignSelf: 'center' }}>
                  {item.privacy_status ? <PublicIcon /> : <LockOutlinedIcon />}
                </div>
              </div>
            ))) : 'No Results' : [1, 2, 3].map(value => <Skeleton key={value} />)}
          </div>
          <div> <hr /></div>
          {showNameField ? (
            <>
              <div>
                Enter the playlist name
              </div>
              <div>
                <TextField
                  id="standard-basic"
                  label="Name"
                  variant="standard"
                  fullWidth
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                  helperText={`${playlistName.length + "/150"}`}
                  inputProps={{ maxLength: 150 }}
                />
              </div>
            </>
          ) : (
            <div className="d-flex ">
              <Button disabled={disable} onClick={handleToggleNameField} sx={{ color: '#000' }} startIcon={<Add />}>
                Create Playlist
              </Button>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <button className='gn-actionbtn mt-3' onClick={playlistCreateHandler} disabled={selectedPlaylist === null && playlistName == '' && loader} >
              {loader ? <CircularProgress size={23} color="inherit" /> : "CREATE"}
            </button>
          </div>

        </Box>
      </Modal>
    </React.Fragment>
  );
}
export default CreatePlaylistModal