import React from 'react'
import { Box, FormControl, IconButton, Stack, TextField, ToggleButton } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'

const SearchBox = ({handleSearchInput,disabled}) => {
  return (
    <div>
        <div>
            <TextField 
                className='searchicon '
                id="standard-bare"
                variant="outlined"
                fullWidth={true}
                size='small'
                input="true" type="text" placeholder="Search.."
                defaultValue=""
                disabled={disabled}
                onChange={(e)=>handleSearchInput(e.target.value)}
                InputProps={{
                startAdornment: (
                    <IconButton  edge="start">
                    <SearchOutlined  />
                    </IconButton>
                ),
                }}
            /> 
      </div>
    </div>
  )
}

export default SearchBox