import { Box,FormControl,IconButton,Slider,Stack,TextField, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { submitDemo } from '../../_services/creater/creatorDemo.service';
import { useState } from 'react';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory,} from 'react-router-dom';
import AddAudio from '../../img/submitSong/addAudio.svg'
import SnackbarContainer from '../Snackbar';
import ConfirmModal from '../../components/reusableComponents/ConfirmModal';
import DemoIndex from './DemoIndex';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { urlValidator } from '../../_helpers/reusablefunctions/urlValidator';


function AddDemo({handleChange}) {
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  let history = useHistory();
  const mdScreen = useMediaQuery('(max-width:1024px)');
  const [data, setData] = useState(
    {
    audio_name:'',
    description:'',
    audio_clip:'',
    demo_link:''
    }
  )
  const [isExternalLink, setIsExternalLink] = React.useState([false]);
  const handleCancel = () => {
    setData({
      audio_name:'',
      description:'',
    });
    setAudioClip()
};
  const [errors, setErrors] = useState({
     audio_name: false,
     audio_name_helperTxt:null,
     audio_clip:false,
     description:false,
     description_helperTxt:null,
     demo_link:false,
     demo_link_helperTxt:null
  })
  const [audio_clip, setAudioClip] = useState(null);
  const [audioClipErr,setAudioClipErr]=useState('')
  const fileInputRef = useRef(null)
  const ref = useRef();
  let smScreen = useMediaQuery('(max-width:768px)')

   /* Detect back or refresh */
   useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
      const confirmationMessage = 'Are you sure you want to leave?';
      event.returnValue = confirmationMessage;
      if (!window.confirm())
      {
        event.preventDefault();
      }
      else
      {
        history.push('/submitdemo')
      }
    };

    const handlePopState = (event) => {
      const confirmationMessage = 'Are you sure you want to leave?';
        if (window.confirm()) {
          // history.push('/submitdemo')
          <DemoIndex/>
        } else {
          // User clicked "Cancel", go back to the current page
          window.history.pushState(null, null, window.location.pathname);
        }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  /* End */

  function validateSongFile(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const fileType = file.type;
      const fileName = file.name;
      const fileSize = file.size;
      const audio = new Audio();
      
      audio.addEventListener('loadedmetadata', () => {
        const audioDuration = audio.duration;
        const maxDuration = 90; // 1 minute 30 seconds in seconds
        
        if (audioDuration > maxDuration) {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage('Audio duration should be less than or equal to 1.5 minutes.'));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          return false;
        }
        
        setAudioClip(reader.result);
        setData({
          ...data,
          ['audio_clip']: file,
        });
        
        return true;
      });
      
      if (file && fileType.startsWith('audio/')) {
        if (!fileName.match(/\.(wav|WAV|mp3|MP3)$/)) {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadTypeDemo));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          return false;
        } else {
          if (fileSize > 20000000) {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadSizeDemo));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            return false;
          } else {
            audio.src = reader.result;
          }
        }
      } else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadTypeDemo));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        return false;
      }
    };
  }
  
  
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateSongFile(file)
    event.target.value = ''; //to set file null
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    validateSongFile(file)
    event.target.value = ''; //to set file null
  };

  const handleInputChange = (event) => {
    const {name, value } = event.target;  
     setData({
     ...data,
     [name]: value
     });
     switch(event.target.name)
     {
      case 'audio_clip':{
        setAudioClip(value)
        setAudioClipErr()
        break;
      }
      case 'audio_name':{
          const isValid = regexValidator(value,'alphanumericEndSpace')
          if(!isValid){
            setErrors({ ...errors, ['audio_name']: true,['audio_name_helperTxt']:formSnackbar.errors.validInput });
          }
          else if(value.length>100)
          {
            setErrors({ ...errors, ['audio_name']: true,['audio_name_helperTxt']:formSnackbar.errors.characterCountErrorlow });
            
          }
          else
          {
            setErrors({ ...errors, ['audio_name']: false,['audio_name_helperTxt']:null });
          }
         break;
      }
      case 'description':{
          const isValid = regexValidator(value,'descriptionText')
          if(!isValid){
            setErrors({ ...errors, ['description']: true,['description_helperTxt']:formSnackbar.errors.validInput });
          }
          else if(value.length>1000)
          {
            setErrors({ ...errors, ['description']: true,['description_helperTxt']:formSnackbar.errors.characterCountErrorMaxOnek });
          }
          else
          {
            setErrors({ ...errors, ['description']: false,['description_helperTxt']:null });
          }
         break;
      }
      case 'demo_link':{
        const isValid = urlValidator(event.target.value)
        if(!isValid){
          setErrors({ ...errors, ['demo_link']: true,['demo_link_helperTxt']:formSnackbar.errors.validInput });
        }
        else
        {
          setErrors({ ...errors, ['demo_link']: false,['demo_link_helperTxt']:null });
        }
        break;
      }
      default:
        {
         return;
        }
     }
   
   }
   
  function saveDemo(e,key) {
    e.preventDefault();
/*     var postData={}
    postData['user'] = userData.id;  */
    dispatch(setSnackBar(true))
    dispatch(setSnackBarVariant('info'))
    dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoUpload))
    dispatch(setLoader(true))
    let postData=data
    postData.userId = userData && userData.id;
    let formdata=new FormData()
    for(key in postData)
    {
       formdata.append(key,data[key]);
    }
    submitDemo(formdata,'POST').then(function (response) {
        if(response.status === 200 || response.status===201)
        {
            dispatch(setLoader(false))
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoUploadSuccess))
            setTimeout(() =>  dispatch(setSnackBar(false)), 2000)
            handleChange()
        }
        else
        {
            dispatch(setLoader(false))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            setTimeout(() =>  dispatch(setSnackBar(false)), 3000)
        }
    })

}
const handleRouteBack = () =>{
  if(data.audio_name !=="" || data.description !=="" || data.audio_clip !=="")
  {
    ref.current.handleOpen()
  }
  else
  {
    handleChange()
  }
}

const confirmHandler = () =>{
  handleChange()
}
// to handle the external link 
const checkboxChangeHandler= (event) =>{
  if(event.target.checked){
    setIsExternalLink([true])
    setAudioClip(null)
    const updatedData = { ...data };
    delete updatedData['audio_clip'];
    setData(updatedData);
    }
  else
  {
    setIsExternalLink([false])
    setErrors({ ...errors, ['demo_link']: false,['demo_link_helperTxt']:null });
    setData({
      ...data,
      audio_clip: '',
    });
  }
}
  return (
  <div>
      <div className='p-3'>
        <button className= "icononly-btn" onClick={handleRouteBack}><ArrowBackIcon /> Back</button>
      </div>
      <div>
          <Box sx={{width:'90%' , margin:'auto',}} className="pb-5">
            <div className="card account-bg  ">
              <div className="card-body border rounded" style={{backgroundColor:'#fff'}}>
                  <p className='cardheading'>Submit your masterpiece </p>
                   <p>Use (.wav or .mp3) files of maximum 20MB size and a maximum duration of 1.5 minutes.</p>
                    <section>    
                      <div> 
                        <label 
                            htmlFor="fileInput" 
                            className="audio-uploader " 
                            style={{pointerEvents:audio_clip || isExternalLink[0]?'none':'auto',opacity:audio_clip||isExternalLink[0]?'0.3':'1'}}
                            onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}
                        >  
                          <div className={`d-flex align-items-center ${mdScreen?'flex-column justify-content-center':'flex-row'}`}  >  
                            <img src={AddAudio} alt="Upload audio"  className="dropImage"  />    
                              <div className='text-center'> 
                                <span className="dragInputText">
                                  Drag and drop audio file here or click to upload *
                                </span>
                              </div>
                          </div> 
                          <input id="fileInput" type="file"  accept="audio/*"   onChange={handleFileInputChange}  ref={fileInputRef} style={{ display: 'none' }}/> 
                        </label> 
                      </div> 
                      {audio_clip && (
                        <div className="d-flex align-items-center mt-3 mx-1">  
                        <div className='flex-grow-1'>  
                        {smScreen?<AudioPlayer type='small' audioSrc={audio_clip}/>:
                           <AudioPlayer  type='large'  audioSrc={audio_clip}/>}
                        </div>          
                        <div>                          
                          <button title="Delete" className='gn-btn-icononly text-secondary' onClick={()=>setAudioClip(null)}><DeleteIcon/></button> 
                        </div>       
                      </div>                 
                      )}
                    </section>
                      <FormControlLabel
                      label="External Link"
                      className='mt-2'
                      control={<Checkbox checked={isExternalLink[0]} onChange={checkboxChangeHandler} />}
                    />
                    {
                      isExternalLink[0] && (<FormControl className='mt-2' fullWidth>
                        <TextField
                          fullWidth
                          value={data.demo_link}
                          id="outlined-required"
                          label="Link*"
                          name='demo_link'   
                          error={errors.demo_link}
                          helperText={errors.demo_link_helperTxt}
                          onChange={handleInputChange}
                        />
                      </FormControl>)
                    }
                    <FormControl className='mt-4' fullWidth>
                      <TextField
                        fullWidth
                        value={data.audio_name}
                        id="outlined-required"
                        label="Song Name*"
                        name='audio_name'   
                        error={errors.audio_name}
                        helperText={errors.audio_name_helperTxt}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl className='mt-4 ' fullWidth>
                      <TextField
                        id="outlined-multiline-static"
                        label="Describe your interest in music*"
                        name='description'
                        multiline
                        type='text'
                        error={errors.description}
                        helperText={errors.description_helperTxt}
                        value={data.description}
                        rows={8}
                        sx={{width:'100%'}}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                                
                  <Box sx={{ display: 'flex', flexDirection: 'row' ,marginTop:'30px'}}>
                    {/* <button
                      color="#aca5a5"
                      sx={{ mr: 1 }}
                      style={{width:'110px',height:'50px'}}
                      className='rounded-pill'
                      onClick={handleCancel}
                    >
                      Cancel
                    </button> */}
                        <Box sx={{ flex: '1 1 auto' }} />
                    <button 
                      className='gn-actionbtn ' 
                      style={{width:'110px'}} 
                      onClick={saveDemo}
                      disabled={(isExternalLink[0] && errors.demo_link) ||
                        (!isExternalLink[0] && (!data.audio_name || !audio_clip)) ||
                        !data.description||
                        errors.audio_name||
                        errors.description
                      }
                    >
                      Submit
                    </button>
                  </Box>
              </div>
            </div>
          </Box>
          <div>
              <SnackbarContainer />
              <ConfirmModal confirmHandler={confirmHandler} ref={ref}/>
          </div>
      </div>
  </div>
  )
}

export default AddDemo