import * as React from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab, tabClasses } from '@mui/base/Tab';
import { useMediaQuery } from '@mui/material';

export default function DynamicToggleTabs({ onSelectTab }) {
  const colorObj = {
    50: '#fff',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#F0F7FF',
    500: 'rgba(217, 217, 217, 1)',
    600: '#000000',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };
  
  const StyledTab = styled(Tab)`
    font-family: 'IBM Plex Sans', sans-serif;
    color: rgba(76, 78, 100, 0.87);
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    line-height: 1.5;
    padding: 8px 12px;
    margin: 6px;
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: rgba(237, 50, 55, 1);
      color: white;
    }
  
    &:focus {
      color: #colorObj;
      outline: 3px solid ${colorObj[200]};
    }
  
    &.${tabClasses.selected} {
      background-color: rgba(237, 50, 55, 1);
      color: white;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const StyledTabPanel = styled(TabPanel)`
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
  `;
  
  const StyledTabsList = styled(TabsList)(
    ({ theme }) => `
    min-width: ${smScreen?'100px':'255px'};
    height: 40px;
    background-color: ${colorObj[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 6px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.4)' : 'rgba(0,0,0, 0.2)'
    };
    `,
  );
  
  const [selectedTab, setSelectedTab] = React.useState(2);
  const smScreen = useMediaQuery('(max-width:600px)');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    onSelectTab(newValue);
  };
  return (
    <Tabs value={selectedTab} onChange={handleTabChange}>
      <StyledTabsList className='m-0 shadow-none'>
        <StyledTab className='p-1' value={2}>Select Cover </StyledTab>
        <StyledTab className='p-1' value={1}>Custom </StyledTab>
      </StyledTabsList>
    </Tabs>
  );
}
