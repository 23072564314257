import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import defaultImage from '../img/default image.png';
import { useMediaQuery } from '@mui/material';

const MAX_CHAR_LIMIT = 50; // Adjust the character limit as needed

function truncateText(text, limit) {
  return text.length > limit ? text.substring(0, limit) + '...' : text;
}

export default function EnquiryList({ cardData, moduleName }) {
  const smScreen = useMediaQuery('(max-width:340px)'); // Adjust the max-width value

  const showInListView = (module) => {
    switch (module) {
      case 'enquiryList': {
        const firstName = cardData.first_name.charAt(0).toUpperCase();
        const truncatedMessage = truncateText(cardData.message, MAX_CHAR_LIMIT);
        const truncatedEmail = smScreen
          ? truncateText(cardData.email, 14) // Truncate email to 20 characters if smScreen is true
          : truncateText(cardData.email, MAX_CHAR_LIMIT); // Truncate email to MAX_CHAR_LIMIT if smScreen is false

        return (
          <div className='my-3 responsive-list' style={{ cursor: 'pointer' }}>
            <Card sx={{ maxWidth: '500px', width: '100%' }}>
              <div className='d-flex w-100'>
                <div style={{ padding: '0.7rem' }}>
                  <CardMedia
                    component="img"
                    sx={{
                      width: '70px',
                      height: '70px',
                      borderRadius: '4px',
                      backgroundColor: '#E41116',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    image={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60'%3E% cx='30' cy='30' r='28' fill='%232196F3' /%3E%3Ctext x='30' y='36' font-size='24' fill='white' font-family='Arial' font-weight='bold' text-anchor='middle'%3E${firstName}%3C/text%3E%3C/svg%3E`}
                    onError={(e) => {
                      e.target.src = defaultImage;
                    }}
                  />
                </div>
                <div style={{ padding: '0.5rem', flexGrow: 1 }}>
                <div title={`${cardData.first_name} ${cardData.last_name}`}>
                    <strong className='cardheadingFont overflow-text'>Name : </strong>
                    {cardData.first_name} {cardData.last_name}
                  </div>
                  <div title={cardData.email}>
                    <strong className='cardheadingFont overflow-text'>Email : </strong>
                    {''}
                    {truncatedEmail}
                  </div>
                  <div>
                    <strong className='cardheadingFont overflow-text'>Mobile No. : </strong>
                    {cardData.mobile_number}
                  </div>
                  <div title={cardData.message}>
                    <strong>Message:</strong> {truncatedMessage}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return showInListView(moduleName);
}
