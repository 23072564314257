import axios from 'axios';
import { getAppToken, getSelfToken } from '../_helpers/auth/secureToken';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      titleVersion: "/releases/title_version/",
      copyrightList: "/releases/copyright/",
      copyrightCreate: "/releases/copyright_add",
      copyrightUpdate: "/releases/copyright_add",
      overviewData: "/releases/overview/studio",
      trackData: "/releases/allsongs/studio",
      albumData: "/releases/allalbums/studio",
      singleDetails: "/releases/songs",
      singleMonthDetails: "/releases/month",
      storeData: "/releases/allplatforms/studio",
      countryData: "/releases/allcountry/studio",
      monthData: "/releases/allmonth/studio",
      videoData: "/releases/allvideo/studio",
      singleAlbumData: "/releases/albums",
      singleCountryData: "/releases/country",
      singlestoreData: "/releases/platform",
    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      let response
      if (endpointKey == "overviewData" || endpointKey == 'trackData'
        || endpointKey == 'albumData' || endpointKey == 'singleDetails' ||
        endpointKey == 'storeData' || endpointKey == 'countryData' || endpointKey == 'monthData' ||
        endpointKey == 'singleMonthDetails' || endpointKey == 'videoData' ||
         endpointKey == 'singleAlbumData' || endpointKey == 'singleCountryData' || endpointKey == 'singlestoreData') {
        response = await this.api.get(endpoint, {
          params,
          // headers: { Authorization: 'Token ' + getSelfToken() },
        })
      } else {
        response = await this.api.get(endpoint, {
          params: getAppToken(),
          headers: { Authorization: 'Token ' + getSelfToken() },
        });
      }
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '?' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
}



export default new APIService(BASE_URL);
